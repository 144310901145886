import React, { memo } from "react";

import { CircleSlider } from "react-circle-slider";

import { averageCalculator, feedbackDetails } from "../../utils";

import {
  ThumbUpOutlined,
  ErrorOutlineOutlined,
  PanToolOutlined,
} from "@material-ui/icons";

const ResultsSection = (props) => {
  const {
    activeSection,
    sectionsNormGroup,
    subSectionsFeedback,
    selectedUsers,
    isUserReport,
    userAssessmentData,
  } = props;

  return (
    <div className="results-container">
      <h2>Your {activeSection.toLowerCase()} results</h2>
      {sectionsNormGroup &&
        sectionsNormGroup[activeSection] &&
        Object.keys(sectionsNormGroup[activeSection])
          .sort()
          .map((subSection) => {
            const normGroupSubSection =
              sectionsNormGroup[activeSection][subSection];
            const normGroupSubSectionsAverageScores = Object.keys(
              normGroupSubSection
            )
              .sort()
              .map((normGroupSubSec) => {
                return normGroupSubSection[normGroupSubSec];
              });

            let selectedUsersScores = [];

            if (isUserReport) {
              selectedUsersScores = [
                userAssessmentData[activeSection][subSection],
              ];
            } else {
              selectedUsers.forEach((selectedUserId) => {
                return normGroupSubSection[selectedUserId]
                  ? selectedUsersScores.push(
                      normGroupSubSection[selectedUserId]
                    )
                  : true;
              });
            }

            let numberOfScoresBelowSelectedUsers = 0;
            normGroupSubSectionsAverageScores.forEach((score) => {
              if (score <= averageCalculator(selectedUsersScores))
                numberOfScoresBelowSelectedUsers += 1;
            });

            const subSectionPercentileRank = Math.round(
              (numberOfScoresBelowSelectedUsers /
                normGroupSubSectionsAverageScores.length) *
                100
            );

            const { feedbackColor, feedbackScore, feedback } = feedbackDetails(
              subSectionPercentileRank
            );

            const feedbackContent = subSectionsFeedback.find(
              (subSectionObj) => {
                return subSection
                  .toLowerCase()
                  .includes(subSectionObj.subSection.toLowerCase());
              }
            );

            return (
              <div className="result-container" key={`${subSection}`}>
                <div className="graph">
                  <div className="stats">
                    <label>Your percantile score</label>
                    <div>
                      <h1 style={{ fontSize: "32px" }}>
                        {subSectionPercentileRank}
                      </h1>
                      <label>%</label>
                    </div>
                  </div>
                  <CircleSlider
                    value={subSectionPercentileRank}
                    onChange={() => {}}
                    disabled
                    knobRadius={5}
                    progressWidth={20}
                    circleWidth={20}
                    circleColor={"rgba(196, 196, 196, 0.3)"}
                    progressColor={feedbackColor}
                  />
                </div>
                <div className="results-content">
                  <p className="feedback" style={{ color: feedbackColor }}>
                    {/* {feedbackScore === "above average" && <ThumbUpOutlined />}
                    {feedbackScore === "below average" && <PanToolOutlined />}
                    {feedbackScore === "average" && <ErrorOutlineOutlined />} */}
                    {feedback}
                  </p>
                  <p className="selector">
                    <strong>{subSection}</strong>
                  </p>
                  <p>
                    {feedbackContent && feedbackContent.feedback[feedbackScore]}
                  </p>
                </div>
              </div>
            );
          })}
    </div>
  );
};

export default memo(ResultsSection);
