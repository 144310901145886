import React, { memo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CookieBanner = ({ cookies, setShowCookieBanner }) => {
  return (
    <div className="cookie-banner">
      <div className="cookie-body">
        <div className="nav-item">
          <div className="cookie-content">
            <h2 style={{ color: "white", opacity: "0.6" }}>
              This website uses cookies
            </h2>
            <p style={{ color: "#FFFFFF" }}>
              We use cookies to help deliver a better experience to you. You can
              read about our policy here:{" "}
              <Link to="cookie-policy" style={{ color: "#FF40D2" }}>
                Learn more
              </Link>
            </p>
          </div>
          <div className="button-container">
            <button
              className="action-button"
              onClick={() => {
                cookies.set("acceptCookies", true);
                setShowCookieBanner(false);
              }}
            >
              Accept
            </button>
            <button
              className="tertiary"
              onClick={() => {
                cookies.set("acceptCookies", false);
                setShowCookieBanner(false);
              }}
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

CookieBanner.propTypes = {
  activeQuestion: PropTypes.number,
  nextQuestion: PropTypes.func,
  previousQuestion: PropTypes.func,
};

CookieBanner.defaultProps = {
  activeQuestion: 0,
  nextQuestion: () => {},
  previousQuestion: () => {},
};

export default memo(CookieBanner);
