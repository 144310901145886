import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
} from "@material-ui/core";

import { UsersTableHeader, UsersRow } from "../../components";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const UsersTable = ({
  classes,
  users,
  history,
  setSelected,
  selected,
  companyFilter,
  search,
  projectName,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("email");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(filteredUsers);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, user) => {
    const selectedIndex = selected.indexOf(user);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, user);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (user) => selected.indexOf(user) !== -1;

  const filteredUsers = stableSort(users, getComparator(order, orderBy))
    .filter((user) => {
      const matchCompanyFilter = companyFilter === user.company;

      if (companyFilter) {
        return matchCompanyFilter;
      } else {
        return (
          (search &&
            ((user &&
              user.company &&
              user.company.toLowerCase().includes(search.toLowerCase())) ||
              (user &&
                user.displayName &&
                user.displayName
                  .toLowerCase()
                  .includes(search.toLowerCase())) ||
              (user &&
                user.email &&
                user.email.toLowerCase().includes(search.toLowerCase())))) ||
          !search
        );
      }
    })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const emptyRows = rowsPerPage - filteredUsers.length;

  return (
    <>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <UsersTableHeader
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody className={classes.tableBody}>
              {filteredUsers.map((user, index) => {
                const isItemSelected = isSelected(user);

                return (
                  <UsersRow
                    user={user}
                    key={`user-row-${index}`}
                    classes={classes}
                    handleClick={handleClick}
                    isItemSelected={isItemSelected}
                    labelId={`enhanced-table-checkbox-${index}`}
                    history={history}
                    projectName={projectName}
                  />
                );
              })}

              {emptyRows > 0 &&
                Array.apply(null, { length: emptyRows }).map((e, i) => (
                  <TableRow style={{ height: "4.5rem" }} key={`empty-row-${i}`}>
                    <TableCell colSpan={6} />
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

UsersTable.propTypes = {
  email: PropTypes.string,
  logout: PropTypes.func,
};

UsersTable.defaultProps = {
  email: "",
  logout: null,
};

export default UsersTable;
