import React, { memo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { stringifyNumber } from "../../utils";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";

const ProgressBar = (props) => {
  const {
    sectionCompleted,
    activeQuestion,
    questions,
    sectionCompletedVal,
    currentSection,
    sectionsRequired,
  } = props;

  const useStyles = makeStyles({
    iconRootActive: {
      color: "#0EA74A",
      display: "flex",
      margin: "0 auto",
    },
    iconRootInactive: {
      color: "transparent",
      display: "flex",
      margin: "0 auto",
    },
  });

  const classes = useStyles();

  let sections = [];

  _.times(sectionsRequired, (index) => {
    const quarter = questions.length / sectionsRequired;
    const sectionProgress =
      activeQuestion - index * (questions.length / sectionsRequired);
    const percentOfSectionRemain = sectionProgress / quarter;
    const incompleted = activeQuestion < quarter * (index + 1);

    sections.push(
      <div className="section-container" key={index}>
        <CheckCircle
          classes={{
            root:
              !incompleted && sectionCompleted
                ? classes.iconRootActive
                : classes.iconRootInactive,
          }}
        />
        {!incompleted ||
        (currentSection === index + 1 && !sectionCompletedVal) ? (
          <p
            style={{
              color: "black",
              textAlign: `${!incompleted && sectionCompleted ? "center" : ""}`,
            }}
          >
            <strong>
              0{index + 1} {stringifyNumber(index + 1)} section
            </strong>
          </p>
        ) : (
          <p
            style={{
              color: "#BFBFBF",
              textAlign: `${!incompleted && sectionCompleted ? "center" : ""}`,
            }}
          >
            <strong>
              0{index + 1} {stringifyNumber(index + 1)} section
            </strong>
          </p>
        )}

        <div
          className={`visual-progress-${
            !sectionCompleted ? "active" : "inactive"
          }`}
        >
          <div
            className={`progress-${!sectionCompleted ? "active" : "inactive"}`}
            style={{
              width: `calc(100% * ${percentOfSectionRemain})`,
            }}
          />
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="progress-container">{sections}</div>

      {!sectionCompletedVal && activeQuestion <= questions.length && (
        <div className="number-progress">
          <p className="subtitle">
            {activeQuestion}/{questions.length}
          </p>
        </div>
      )}
    </>
  );
};

ProgressBar.propTypes = {
  activeQuestion: PropTypes.number,
  sections: PropTypes.array,
  questions: PropTypes.array,
};

ProgressBar.defaultProps = {
  activeQuestion: 0,
  sections: [],
  questions: [],
};

export default memo(ProgressBar);
