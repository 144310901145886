import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import firebase from "../../firebase";

import { TableCell, TableRow, Checkbox } from "@material-ui/core";

const UsersRow = ({
  user,
  classes,
  handleClick,
  isItemSelected,
  labelId,
  projectName,
}) => {
  const encodedURL = encodeURI(user.company);

  return (
    <TableRow
      classes={{
        selected: classes.selected,
        hover: classes.hover,
      }}
      className={`${classes.tableRow} user`}
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={user.email}
      selected={isItemSelected}
    >
      <TableCell
        padding="checkbox"
        classes={{ root: classes.cellRoot }}
        onClick={(event) => handleClick(event, user)}
      >
        <Checkbox
          style={{
            color: "#4D71F7",
          }}
          size="medium"
          checked={isItemSelected}
          inputProps={{ "aria-labelledby": labelId }}
        />
      </TableCell>
      <TableCell
        component="th"
        id={labelId}
        scope="row"
        classes={{ root: classes.cellRoot }}
      >
        {user.displayName}
      </TableCell>
      <TableCell align="left" classes={{ root: classes.cellRoot }}>
        <Link to={`/${projectName}/user/${user.uid}`}>{user.email}</Link>
      </TableCell>
      <TableCell align="left" classes={{ root: classes.cellRoot }}>
        <a href={`/${projectName}/company/${encodeURI(encodedURL)}`}>
          {user.company}
        </a>
      </TableCell>
      <TableCell
        align="center"
        classes={{ root: classes.cellRoot }}
        style={{
          color: `${user.pendingResults ? "#D96600" : "#0C9162"}`,
        }}
      >
        {user.pendingResults ? "Pending" : "Completed"}
      </TableCell>
    </TableRow>
  );
};

export default UsersRow;
