import React from "react";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    // position: 'fixed',
    // bottom: '1rem',
    // right: '1rem',
    width: "max-content",
    background: "#404040",
    color: "white",
  },
}));

const AppAlert = ({ children }) => {
  const classes = useStyles();

  return (
    <Alert
      classes={{ root: classes.root }}
      icon={false}
      severity="info"
      variant="filled"
    >
      {children}
    </Alert>
  );
};

export default AppAlert;
