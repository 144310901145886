import React, { memo, useState, useEffect } from "react";
import firebase from "../../firebase";
import _ from "lodash";
import * as moment from "moment";

import { Modal } from "@material-ui/core";

import {
  KeyboardArrowLeft,
  FileCopy,
  Lock,
  Add,
  Edit,
  Delete,
} from "@material-ui/icons";

const CMS = ({ history, match }) => {
  const [assessmentVersions, setAssessmentVersions] = useState([]);
  const [liveVersion, setLiveVersion] = useState(null);
  const [archivedVersions, setArchivedVersions] = useState([]);

  const { projectName } = match.params;

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            /**
             * CHECK CURRENT USER IS ADMIN
             */
            const { claims } = idTokenResult;

            if (!claims.isAdmin) {
              history.goBack();
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });
  }, [history]);

  useEffect(() => {
    const db = firebase.firestore();
    const liveAssessmentVersion = db
      .collection(projectName)
      .doc("cms")
      .collection("published")
      .doc("live");

    const unsubscribe = liveAssessmentVersion.onSnapshot((doc) => {
      if (!_.isEqual(liveVersion, doc.data().version)) {
        setLiveVersion(doc.data().version);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const db = firebase.firestore();

    const archivedAssessmentVersions = db
      .collection(projectName)
      .doc("cms")
      .collection("published")
      .doc("archived");

    const unsubscribe = archivedAssessmentVersions.onSnapshot((doc) => {
      if (!_.isEqual(archivedVersions, doc.data().version)) {
        setArchivedVersions(doc.data().version ? doc.data().version : []);
      }
    });

    const getData = async () => {
      const cms = await db
        .collection(projectName)
        .doc("cms")
        .collection("version")
        .get();

      const test = cms.docs.map((doc) => {
        return doc.id;
      });

      return test;
    };

    getData().then((response) => {
      if (!_.isEqual(response, assessmentVersions)) {
        setAssessmentVersions(response ? response : []);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <div className="page">
        <div className="toolbar">
          <div className="toolbar-items">
            <button
              className="secondary back"
              onClick={() => {
                history.push(`/${projectName}/users`);
              }}
              disabled={false}
            >
              <KeyboardArrowLeft />
              Back and save
            </button>
          </div>

          <div className="toolbar-items">
            <h1 className="questions-title">CMS versions</h1>
          </div>

          <div className="toolbar-items"></div>
        </div>

        {assessmentVersions.map((version, index) => {
          const enabled =
            version !== liveVersion && !archivedVersions.includes(version);

          let publishText = "";

          if (version === liveVersion) {
            publishText = "Live";
          } else if (archivedVersions && archivedVersions.includes(version)) {
            publishText = "Archived";
          } else {
            publishText = "Draft";
          }

          return (
            <div className={`edit-version-container `} key={index}>
              <div className="publish-information">
                <div>
                  <p>
                    <strong>Version: </strong>#{version}
                  </p>
                  <label style={{ fontSize: "10px" }}>
                    {enabled ? "Created" : "Published"} on{" "}
                    {moment(parseInt(version)).format("LLLL")}
                  </label>
                </div>

                <div className="status-container">
                  <p className={`subtitle ${publishText}`}>{publishText}</p>
                  {version === liveVersion ? (
                    <div className="live" />
                  ) : archivedVersions && archivedVersions.includes(version) ? (
                    <Lock style={{ fill: "#bfbfbf" }} />
                  ) : (
                    <div className="draft" />
                  )}
                </div>
              </div>

              <div className="actions-container">
                <button
                  className="secondary"
                  onClick={() => {
                    const db = firebase.firestore();
                    const currentTime = Date.now().toString();

                    const cmsVersions = db
                      .collection(projectName)
                      .doc("cms")
                      .collection("version")
                      .doc(currentTime);
                    cmsVersions.set({}, { merge: true });

                    const cmsOldVersionAssessment = db
                      .collection(projectName)
                      .doc("cms")
                      .collection("version")
                      .doc(version)
                      .collection("assessment")
                      .doc("sections")
                      .get();

                    const cmsNewVersionAssessment = db
                      .collection(projectName)
                      .doc("cms")
                      .collection("version")
                      .doc(currentTime)
                      .collection("assessment")
                      .doc("sections");

                    cmsOldVersionAssessment.then((doc) => {
                      cmsNewVersionAssessment.set(doc.data());
                    });

                    const cmsNewVersionOnboarding = db
                      .collection(projectName)
                      .doc("cms")
                      .collection("version")
                      .doc(currentTime)
                      .collection("onboarding")
                      .doc("questions");

                    const cmsOldVersionOnboarding = db
                      .collection(projectName)
                      .doc("cms")
                      .collection("version")
                      .doc(version)
                      .collection("onboarding")
                      .doc("questions")
                      .get();

                    cmsOldVersionOnboarding.then((doc) => {
                      cmsNewVersionOnboarding.set(
                        {
                          questions: doc.data().questions,
                        },
                        { merge: true }
                      );
                    });
                    setAssessmentVersions([...assessmentVersions, currentTime]);
                  }}
                  disabled={false}
                >
                  <FileCopy style={{ paddingRight: 0 }} />
                </button>

                <button
                  className="secondary"
                  onClick={() => history.push(`/${projectName}/cms/${version}`)}
                  disabled={false}
                >
                  {enabled ? <Edit style={{ paddingRight: 0 }} /> : "View"}
                </button>

                <button
                  className="secondary"
                  onClick={() => {
                    const db = firebase.firestore();

                    const cmsVersionOnboarding = db
                      .collection(projectName)
                      .doc("cms")
                      .collection("version")
                      .doc(version)
                      .collection("onboarding")
                      .doc("questions");

                    cmsVersionOnboarding.delete();

                    const cmsAssessmentVersions = db
                      .collection(projectName)
                      .doc("cms")
                      .collection("version")
                      .doc(version)
                      .collection("assessment")
                      .doc("sections");

                    cmsAssessmentVersions.delete();

                    const cmsVersions = db
                      .collection(projectName)
                      .doc("cms")
                      .collection("version")
                      .doc(version);

                    cmsVersions.delete();

                    let items;
                    items = [...assessmentVersions];
                    items.splice(index, 1);
                    setAssessmentVersions(items);
                  }}
                  disabled={!enabled}
                >
                  <Delete style={{ paddingRight: 0 }} />
                </button>

                <button
                  className="publish"
                  onClick={() => {
                    const db = firebase.firestore();
                    const archivedAssessmentVersion = db
                      .collection(projectName)
                      .doc("cms")
                      .collection("published")
                      .doc("archived");

                    archivedAssessmentVersion.update(
                      {
                        version: firebase.firestore.FieldValue.arrayUnion(
                          liveVersion
                        ),
                      },
                      { merge: false }
                    );

                    const liveAssessmentVersion = db
                      .collection(projectName)
                      .doc("cms")
                      .collection("published")
                      .doc("live");

                    liveAssessmentVersion.set(
                      {
                        version: version.toString(),
                      },
                      { merge: false }
                    );
                  }}
                  disabled={version === liveVersion}
                >
                  Publish
                </button>
              </div>
            </div>
          );
        })}

        <button
          className="add-another-version-container"
          onClick={() => {
            const currentTime = Date.now().toString();

            setAssessmentVersions([...assessmentVersions, currentTime]);
            const db = firebase.firestore();
            const cmsVersions = db
              .collection(projectName)
              .doc("cms")
              .collection("version")
              .doc(currentTime);
            cmsVersions.set({}, { merge: true });

            const cmsVersionsWithSections = db
              .collection(projectName)
              .doc("cms")
              .collection("version")
              .doc(currentTime)
              .collection("assessment")
              .doc("sections");

            cmsVersionsWithSections.set({}, { merge: true });

            const cmsVersionOnboarding = db
              .collection(projectName)
              .doc("cms")
              .collection("version")
              .doc(currentTime)
              .collection("onboarding")
              .doc("questions");

            cmsVersionOnboarding.set(
              {
                questions: [],
              },
              { merge: true }
            );
          }}
        >
          <p className="add-another-version-content">
            <Add />
            Add version
          </p>
        </button>
      </div>
    </>
  );
};

export default memo(CMS);
