import React, { memo, useState, useEffect } from "react";
import firebase from "../../firebase";
import _ from "lodash";

import { KeyboardArrowLeft, Cancel, Add } from "@material-ui/icons";

import ChipInput from "material-ui-chip-input";

const CMSOnboarding = ({ history, match }) => {
  const [onboardingQuestions, setOnboardingQuestions] = useState([]);
  const [liveVersion, setLiveVersion] = useState(null);
  const [archivedVersion, setArchivedVersion] = useState([]);

  const { projectName, assessmentVersion } = match.params;

  const setOnboardingQuestion = (data, index) => {
    let items;
    items = [...onboardingQuestions];
    let item = { ...items[index] };
    item = data;
    items[index] = item;
    setOnboardingQuestions(items);
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            /**
             * CHECK CURRENT USER IS ADMIN
             */
            const { claims } = idTokenResult;

            if (!claims.isAdmin) {
              history.goBack();
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });
  }, [history]);

  useEffect(() => {
    const db = firebase.firestore();
    const liveAssessmentVersion = db
      .collection(projectName)
      .doc("cms")
      .collection("published")
      .doc("live");

    const unsubscribe = liveAssessmentVersion.onSnapshot((doc) => {
      if (!_.isEqual(liveVersion, doc.data().version)) {
        setLiveVersion(doc.data().version);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const db = firebase.firestore();
    const archivedAssessmentVersion = db
      .collection(projectName)
      .doc("cms")
      .collection("published")
      .doc("archived");

    const unsubscribe = archivedAssessmentVersion.onSnapshot((doc) => {
      if (!_.isEqual(archivedVersion, doc.data().version)) {
        setArchivedVersion(doc.data().version);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const db = firebase.firestore();
    const cmsOnboarding = db
      .collection(projectName)
      .doc("cms")
      .collection("version")
      .doc(assessmentVersion)
      .collection("onboarding")
      .doc("questions")
      .get();

    cmsOnboarding
      .then((doc) => {
        if (!_.isEqual(doc.data().questions, onboardingQuestions)) {
          setOnboardingQuestions(doc.data().questions);
        }
      })
      .catch((e) => {
        console.error(`error message: ${e.message}`);
      });
  }, []);

  const isLiveOrArchived =
    liveVersion === assessmentVersion ||
    archivedVersion.includes(assessmentVersion);

  return (
    <>
      <div className="page">
        <div className="toolbar">
          <div className="toolbar-items">
            <button
              className="secondary back"
              onClick={() => {
                const db = firebase.firestore();
                const cmsOnboarding = db
                  .collection(projectName)
                  .doc("cms")
                  .collection("version")
                  .doc(assessmentVersion)
                  .collection("onboarding")
                  .doc("questions");

                cmsOnboarding.set(
                  {
                    questions: onboardingQuestions,
                  },
                  { merge: true }
                );
                history.push(`/${projectName}/cms/${assessmentVersion}`);
              }}
              disabled={false}
            >
              <KeyboardArrowLeft />
              Back and save
            </button>
          </div>
          <div className="toolbar-items">
            <h1 className="questions-title">Edit questions</h1>
          </div>

          <div className="toolbar-items"></div>
        </div>
        <div className="add-question-container">
          {onboardingQuestions.length > 0 &&
            onboardingQuestions.map(({ body, options }, index) => {
              return (
                <div className="question-container" key={`question-${index}`}>
                  <div className="question-inputs">
                    <div>
                      <p
                        style={{
                          margin: "0 0 1rem 0",
                        }}
                      >
                        <strong>Question {index + 1}</strong>
                      </p>
                      <label>Body</label>
                      <input
                        value={body}
                        label="body"
                        onChange={(e) => {
                          if (!isLiveOrArchived) {
                            setOnboardingQuestion(
                              {
                                body: e.target.value,
                                options,
                              },
                              index
                            );
                          }
                        }}
                        disabled={isLiveOrArchived}
                      />
                    </div>

                    <div className="options-container">
                      <label>Options</label>
                      <ChipInput
                        defaultValue={options.map(({ value }) => value)}
                        allowDuplicates={false}
                        style={{
                          margin: "0.5rem 0",
                          background: "#ffffff",
                          border: "1px solid #c9c9c9",
                          boxSizing: "border-box",
                          borderRadius: "4px",
                          padding: "0.75rem 1rem",
                          width: "100%",
                          fontSize: "16px",
                          fontFamily: "HK Grotesk",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          lineHeight: "140%",
                          outline: "#4d71f7",
                          maxWidth: "100%",
                        }}
                        disabled={isLiveOrArchived}
                        disableUnderline
                        onChange={(options) => {
                          if (!isLiveOrArchived) {
                            setOnboardingQuestion(
                              {
                                options: options.map((option) => {
                                  return { value: option };
                                }),
                                body,
                              },
                              index
                            );
                          }
                        }}
                      />
                    </div>
                  </div>

                  {!isLiveOrArchived && (
                    <div className="delete-container">
                      <Cancel
                        onClick={() => {
                          let items;
                          items = [...onboardingQuestions];
                          const lengthBefore = items.length;
                          items.splice(index, 1);
                          const lengthAfter = items.length;

                          if (lengthBefore !== lengthAfter) {
                            setOnboardingQuestions(items);
                          }
                        }}
                        disabled={false}
                      />
                    </div>
                  )}
                </div>
              );
            })}

          {!isLiveOrArchived && (
            <button
              className={`add-another-user-container `}
              onClick={() => {
                setOnboardingQuestions([
                  ...onboardingQuestions,
                  {
                    body: "",
                    options: [],
                  },
                ]);
              }}
              disabled={false}
            >
              <p className={`add-another-user-content`}>
                <Add />
                Add question
              </p>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(CMSOnboarding);
