import React, { memo, useState } from "react";

import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

const EditQuestionRadioGroup = ({
  value,
  score,
  optionIndex,
  options,
  assessmentQuestionOptions,
  setAssessmentQuestionOptions,
  questionIndex,
  disabled,
}) => {
  const [optionScore, setOptionScore] = useState(score);

  return (
    <div className="option-container" key={`option-${optionIndex}`}>
      <p
        className="subtitle"
        style={{
          padding: 0,
          margin: 0,
        }}
      >
        <strong>
          {value && value.charAt(0).toUpperCase() + value.slice(1)}
        </strong>
      </p>
      <FormControl component="fieldset" style={{ minWidth: "50%" }}>
        <RadioGroup
          aria-label="score"
          name="score"
          value={optionScore}
          onChange={(e) => {
            if (!disabled) {
              setOptionScore(parseInt(e.target.value));

              options[optionIndex] = {
                score: parseInt(e.target.value),
                value,
              };
              let items;
              items = [...assessmentQuestionOptions];
              let item = { ...items[questionIndex] };
              item = {
                options,
              };
              items[questionIndex] = item;
              setAssessmentQuestionOptions(items);
            }
          }}
        >
          <FormControlLabel
            value={0}
            control={<Radio />}
            label={optionIndex ? "" : 0}
            disabled={disabled}
          />
          <FormControlLabel
            value={1}
            control={<Radio />}
            label={optionIndex ? "" : 1}
            disabled={disabled}
          />
          <FormControlLabel
            value={2}
            control={<Radio />}
            label={optionIndex ? "" : 2}
            disabled={disabled}
          />
          <FormControlLabel
            value={4}
            control={<Radio />}
            label={optionIndex ? "" : 4}
            disabled={disabled}
          />
          <FormControlLabel
            value={5}
            control={<Radio />}
            label={optionIndex ? "" : 5}
            disabled={disabled}
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default memo(EditQuestionRadioGroup);
