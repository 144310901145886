import React, { memo } from "react";

import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";

const ResultsHeader = (props) => {
  const { activeSection, selectSection, sectionsSummary } = props;

  return (
    <>
      <ToggleButtonGroup
        value={activeSection}
        exclusive
        onChange={selectSection}
        aria-label="text alignment"
      >
        <ToggleButton value="summary" aria-label="">
          <p className="subtitle">
            <strong>Summary</strong>
          </p>
        </ToggleButton>
        {sectionsSummary.map(({ section }) => {
          return (
            <ToggleButton value={section} aria-label="" key={`${section}`}>
              <p className="subtitle">
                <strong>{section}</strong>
              </p>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>

      {sectionsSummary.map(
        ({ body, section }) =>
          section.toLowerCase() === activeSection.toLowerCase() && (
            <div className="summary" key={`${section}`}>
              <p className="selector">
                <strong>What is {section.toLowerCase()}?</strong>
              </p>
              <p>{body}</p>
            </div>
          )
      )}
    </>
  );
};

export default memo(ResultsHeader);
