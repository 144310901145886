import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { columnOne, columnTwo } from "../../assets/data/faq";

import {
  KeyboardArrowUp,
  KeyboardArrowLeft,
  LiveHelp,
  Remove,
  Add,
} from "@material-ui/icons";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import { FooterV2 } from "../../components";

const useStyles = makeStyles((theme) => ({
  heading: {
    padding: "0.5rem 0",
  },
  accordionRoot: {
    background: "none",
    boxShadow: "none",
    borderTop: "2px solid #000000",
    borderRadius: "0 !important",
    width: "100%",
  },
  accordionDetails: {
    padding: 0,
  },
  accordionSummary: {
    padding: 0,
    margin: 0,
  },
}));

const FAQ = (props) => {
  const { history, match } = props;
  const classes = useStyles();
  const { projectName } = match.params;

  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const hideLinks =
    match.url.includes("faq") ||
    match.url.includes("cookie-policy") ||
    match.url.includes("privacy-policy-long");

  return (
    <>
      <div className="page">
        <button
          className="secondary back"
          onClick={() => history.goBack()}
          disabled={false}
        >
          <KeyboardArrowLeft />
          Back
        </button>
        <div className="faq-page">
          <LiveHelp />
          <h1 className="title">Frequently asked questions</h1>
          <div className="accordion-container">
            <div className="accordion-column">
              {columnOne.map(({ title, body }, index) => {
                const currentIndex = index + 1;

                return (
                  <Accordion
                    key={currentIndex}
                    onChange={handleChange(`panel${currentIndex}`)}
                    expanded={expanded === `panel${currentIndex}`}
                    className={`${classes.accordionRoot} ${
                      currentIndex === columnOne.length
                        ? "accordion-bottom"
                        : ""
                    }`}
                  >
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={
                        expanded === `panel${currentIndex}` ? (
                          <Remove style={{ fill: "#4D71F7" }} />
                        ) : (
                          <Add style={{ fill: "#4D71F7" }} />
                        )
                      }
                      aria-controls={`panel${currentIndex}a-content`}
                      id={`panel${currentIndex}a-header`}
                    >
                      <p className={`subtitle ${classes.heading}`}>
                        <strong>{title}</strong>
                      </p>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <p>{body}</p>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>

            {columnTwo.length > 0 && (
              <div className="accordion-column">
                {columnTwo.map(({ title, body }, index) => {
                  const currentIndex = index + 1;
                  const columnTwoCurrentIndex = currentIndex + columnOne.length;

                  return (
                    <Accordion
                      key={columnTwoCurrentIndex}
                      onChange={handleChange(`panel${columnTwoCurrentIndex}`)}
                      expanded={expanded === `panel${columnTwoCurrentIndex}`}
                      className={`${classes.accordionRoot} ${
                        columnOne.length + currentIndex ===
                        columnOne.length + columnTwo.length
                          ? "accordion-bottom"
                          : ""
                      }`}
                    >
                      <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={
                          expanded === `panel${columnTwoCurrentIndex}` ? (
                            <Remove style={{ fill: "#4D71F7" }} />
                          ) : (
                            <Add style={{ fill: "#4D71F7" }} />
                          )
                        }
                        aria-controls={`panel${columnTwoCurrentIndex}a-content`}
                        id={`panel${columnTwoCurrentIndex}a-header`}
                      >
                        <p className={`subtitle ${classes.heading}`}>
                          <strong>{title}</strong>
                        </p>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetails}>
                        <p>{body}</p>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            )}
          </div>
          <p className="further-information">
            <strong>
              Find out more about this service at our website{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.equalityforward.com"
              >
                www.equalityforward.com
              </a>
            </strong>
          </p>
          <button
            className="secondary see-more"
            onClick={scrollTop}
            disabled={false}
          >
            <KeyboardArrowUp />
            Go to the top
          </button>
        </div>
      </div>

      <FooterV2 projectName={projectName} hideLinks={hideLinks} />
    </>
  );
};

export default FAQ;
