import React, { memo, useState, useEffect } from "react";
import firebase from "../../firebase";
import _ from "lodash";

import { KeyboardArrowLeft } from "@material-ui/icons";

import { EditQuestions } from "../../components";
import { CircularProgress } from "@material-ui/core";

const CMSAssessmentQuestions = ({ history, match }) => {
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const [assessmentQuestionOptions, setAssessmentQuestionOptions] = useState(
    []
  );
  const [saving, setSaving] = useState(false);
  const [liveVersion, setLiveVersion] = useState(null);
  const [archivedVersion, setArchivedVersion] = useState([]);

  const {
    projectName,
    assessmentVersion,
    sectionId,
    subSectionId,
  } = match.params;

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            /**
             * CHECK CURRENT USER IS ADMIN
             */
            const { claims } = idTokenResult;

            if (!claims.isAdmin) {
              history.goBack();
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });
  }, [history]);

  useEffect(() => {
    const db = firebase.firestore();
    const archivedAssessmentVersion = db
      .collection(projectName)
      .doc("cms")
      .collection("published")
      .doc("archived");

    const unsubscribe = archivedAssessmentVersion.onSnapshot((doc) => {
      if (!_.isEqual(archivedVersion, doc.data().version)) {
        setArchivedVersion(doc.data().version);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const db = firebase.firestore();
    const liveAssessmentVersion = db
      .collection(projectName)
      .doc("cms")
      .collection("published")
      .doc("live");

    const unsubscribe = liveAssessmentVersion.onSnapshot((doc) => {
      if (!_.isEqual(liveVersion, doc.data().version)) {
        setLiveVersion(doc.data().version);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const db = firebase.firestore();
    const cmsQuestions = db
      .collection(projectName)
      .doc("cms")
      .collection("version")
      .doc(assessmentVersion)
      .collection("assessment")
      .doc("sections")
      .get();

    cmsQuestions
      .then((doc) => {
        if (
          doc.data() &&
          doc.data()[sectionId] &&
          doc.data()[sectionId].subsections &&
          doc.data()[sectionId].subsections[subSectionId]
        ) {
          const subsectionData = doc.data()[sectionId].subsections[subSectionId]
            .questions;

          const assessmentQuestionsBody = subsectionData.map(({ body }) => {
            return {
              body,
            };
          });

          const assessmentQuestionsOptions = subsectionData.map(
            ({ options }) => {
              return {
                options,
              };
            }
          );

          if (!_.isEqual(assessmentQuestions, assessmentQuestionsBody)) {
            setAssessmentQuestions(assessmentQuestionsBody);
          }

          if (
            !_.isEqual(assessmentQuestionOptions, assessmentQuestionsOptions)
          ) {
            setAssessmentQuestionOptions(assessmentQuestionsOptions);
          }
        }
      })
      .catch((e) => {
        console.error(`error message: ${e.message}`);
      });
  }, []);

  const isLiveOrArchived =
    liveVersion === assessmentVersion ||
    archivedVersion.includes(assessmentVersion);

  return (
    <>
      <div className="page">
        <div className="toolbar">
          <div className="toolbar-items">
            <button
              className="secondary back"
              onClick={() => {
                const db = firebase.firestore();
                const cmsQuestions = db
                  .collection(projectName)
                  .doc("cms")
                  .collection("version")
                  .doc(assessmentVersion)
                  .collection("assessment")
                  .doc("sections");

                const mergedQuestionsAndOptions = assessmentQuestions.map(
                  ({ body }, questionIndex) => {
                    return {
                      body,
                      options: assessmentQuestionOptions[questionIndex].options,
                    };
                  }
                );

                cmsQuestions.update({
                  [`${sectionId}.subsections.${subSectionId}.questions`]: mergedQuestionsAndOptions,
                });

                setSaving(true);
                setTimeout(() => {
                  setSaving(false);
                  history.push(
                    `/${projectName}/cms/${assessmentVersion}/assessment/sections/${sectionId}/subSections`
                  );
                }, 3000);
              }}
              disabled={saving}
            >
              {saving ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    style={{
                      width: "1.25rem",
                      height: "1.25rem",
                      fill: "black",
                      color: "black",
                      marginRight: "0.5rem",
                    }}
                  />
                  Saving
                </div>
              ) : (
                <>
                  <KeyboardArrowLeft />
                  Back and save
                </>
              )}
            </button>
          </div>
          <div className="toolbar-items">
            <h1 className="questions-title">{subSectionId}</h1>
          </div>

          <div className="toolbar-items"></div>
        </div>

        {saving ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              style={{
                width: "1.25rem",
                height: "1.25rem",
                fill: "black",
                color: "black",
                marginRight: "0.5rem",
              }}
            />
          </div>
        ) : (
          <EditQuestions
            assessmentQuestions={assessmentQuestions}
            setAssessmentQuestions={setAssessmentQuestions}
            setAssessmentQuestionOptions={setAssessmentQuestionOptions}
            disabled={isLiveOrArchived}
            assessmentQuestionOptions={assessmentQuestionOptions}
          />
        )}
      </div>
    </>
  );
};

export default memo(CMSAssessmentQuestions);
