import React, { memo, useState, useEffect } from "react";
import firebase from "../../firebase";
import _ from "lodash";

import { KeyboardArrowLeft, Done } from "@material-ui/icons";

const CMSAssessmentVersion = ({ history, match }) => {
  const [assessmentQuestions, setAssessmentQuestions] = useState(0);
  const [onboardingQuestions, setOnboardingQuestions] = useState(0);

  const { projectName, assessmentVersion } = match.params;

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            /**
             * CHECK CURRENT USER IS ADMIN
             */
            const { claims } = idTokenResult;

            if (!claims.isAdmin) {
              history.goBack();
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });
  }, [history]);

  useEffect(() => {
    const db = firebase.firestore();

    const cmsAssessment = db
      .collection(projectName)
      .doc("cms")
      .collection("version")
      .doc(assessmentVersion)
      .collection("assessment")
      .doc("sections")
      .get();

    const cmsOnboarding = db
      .collection(projectName)
      .doc("cms")
      .collection("version")
      .doc(assessmentVersion)
      .collection("onboarding")
      .doc("questions")
      .get();

    cmsAssessment
      .then((doc) => {
        const totalQuestionsSectionArray = Object.keys(doc.data())
          .sort()
          .map((sectionName) => {
            const totalQuestionsSubsectionArray = Object.keys(
              doc.data()[sectionName].subsections
            )
              .sort()
              .map((subsectionName) => {
                return doc.data()[sectionName].subsections[subsectionName]
                  .questions.length;
              });

            const totalQuestionsSubsection = totalQuestionsSubsectionArray.reduce(
              (a, b) => a + b,
              0
            );

            return totalQuestionsSubsection;
          });

        const totalQuestions = totalQuestionsSectionArray.reduce(
          (a, b) => a + b,
          0
        );

        if (!_.isEqual(totalQuestions, assessmentQuestions)) {
          setAssessmentQuestions(totalQuestions);
        }
      })
      .catch((e) => {
        console.error(`error message: ${e.message}`);
      });

    cmsOnboarding
      .then((doc) => {
        if (!_.isEqual(doc.data().questions.length, onboardingQuestions)) {
          setOnboardingQuestions(doc.data().questions.length);
        }
      })
      .catch((e) => {
        console.error(`error message: ${e.message}`);
      });
  });

  const cmsHeaderTitle = ["Assessment", "Onboarding"];

  return (
    <>
      <div className="page">
        <div className="toolbar">
          <div className="toolbar-items">
            <button
              className="secondary back"
              onClick={() => history.push(`/${projectName}/cms`)}
              disabled={false}
            >
              <KeyboardArrowLeft />
              Back and save
            </button>
          </div>

          <div className="toolbar-items"></div>

          <div className="toolbar-items"></div>
        </div>

        <div className="cms-assessment-version-container">
          {cmsHeaderTitle.length > 0 &&
            cmsHeaderTitle.map((cmsHeader, index) => {
              const toLowerCase = cmsHeader && cmsHeader.toLowerCase();

              return (
                <div className="edit-assessment-version-container" key={index}>
                  <div className="publish-information">
                    <div className="version-data">
                      <p className="subtitle">
                        <strong>{cmsHeader}</strong>
                      </p>
                    </div>
                    <div className="version-stats-container">
                      <div className="version-stats">
                        <p className="subtitle">
                          {cmsHeader === "Onboarding"
                            ? onboardingQuestions
                            : assessmentQuestions}{" "}
                          Questions
                        </p>
                      </div>
                    </div>
                  </div>
                  <button
                    className="secondary"
                    onClick={() =>
                      history.push(
                        `/${projectName}/cms/${assessmentVersion}/${toLowerCase}${
                          toLowerCase === "onboarding" ? "" : "/sections"
                        }`
                      )
                    }
                    disabled={false}
                  >
                    View
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default memo(CMSAssessmentVersion);
