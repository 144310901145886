import React, { memo, useState, useEffect } from "react";
import firebase from "../../firebase";
import _ from "lodash";

import {
  KeyboardArrowLeft,
  ThumbUpOutlined,
  PanToolOutlined,
  ErrorOutlineOutlined,
  Add,
  Cancel,
} from "@material-ui/icons";

import { CircularProgress } from "@material-ui/core";

const CMSAssessmentSubsections = ({ history, match }) => {
  const [liveVersion, setLiveVersion] = useState(null);
  const [assessmentSubsections, setAssessmentSubsections] = useState([]);
  const [
    assessmentSubsectionsFeedback,
    setAssessmentSubsectionsFeedback,
  ] = useState([]);
  const [
    assessmentSubsectionsQuestions,
    setAssessmentSubsectionsQuestions,
  ] = useState([]);
  const [archivedVersion, setArchivedVersion] = useState([]);
  const { projectName, assessmentVersion, sectionId } = match.params;
  const db = firebase.firestore();
  const [saving, setSaving] = useState(false);

  const cmsQuestions = db
    .collection(projectName)
    .doc("cms")
    .collection("version")
    .doc(assessmentVersion)
    .collection("assessment")
    .doc("sections");

  const archivedAssessmentVersion = db
    .collection(projectName)
    .doc("cms")
    .collection("published")
    .doc("archived");

  const liveAssessmentVersion = db
    .collection(projectName)
    .doc("cms")
    .collection("published")
    .doc("live");

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            /**
             * CHECK CURRENT USER IS ADMIN
             */
            const { claims } = idTokenResult;

            if (!claims.isAdmin) {
              history.goBack();
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });
  }, [history]);

  useEffect(() => {
    const unsubscribe = archivedAssessmentVersion.onSnapshot((doc) => {
      if (doc.data()) {
        if (!_.isEqual(archivedVersion, doc.data().version)) {
          setArchivedVersion(doc.data().version);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = liveAssessmentVersion.onSnapshot((doc) => {
      if (doc.data()) {
        if (!_.isEqual(liveVersion, doc.data().version)) {
          setLiveVersion(doc.data().version);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = cmsQuestions.onSnapshot((doc) => {
      if (
        doc.data() &&
        doc.data()[sectionId] &&
        doc.data()[sectionId].subsections
      ) {
        if (doc.data()[sectionId]) {
          const subSections = Object.keys(
            doc.data()[sectionId].subsections
              ? doc.data()[sectionId].subsections
              : {}
          ).sort();

          const subSectionsFeedback =
            subSections.length > 0 &&
            subSections.map((subsectionKey) => {
              return doc.data()[sectionId].subsections[subsectionKey].feedback;
            });

          if (!_.isEqual(subSectionsFeedback, assessmentSubsectionsFeedback)) {
            if (subSectionsFeedback) {
              setAssessmentSubsectionsFeedback(subSectionsFeedback);
            } else {
              setAssessmentSubsectionsFeedback([
                {
                  "above average": "",
                  average: "",
                  "below average": "",
                },
              ]);
            }
          }
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = cmsQuestions.onSnapshot((doc) => {
      if (
        doc.data() &&
        doc.data()[sectionId] &&
        doc.data()[sectionId].subsections
      ) {
        const subSections = Object.keys(
          doc.data()[sectionId].subsections
            ? doc.data()[sectionId].subsections
            : {}
        ).sort();

        const subSectionsQuestions =
          subSections.length > 0 &&
          subSections.map((subsectionKey) => {
            return doc.data()[sectionId].subsections[subsectionKey].questions;
          });

        if (!_.isEqual(subSectionsQuestions, assessmentSubsectionsQuestions)) {
          if (subSectionsQuestions) {
            setAssessmentSubsectionsQuestions(subSectionsQuestions);
          } else {
            setAssessmentSubsectionsQuestions([]);
          }
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = cmsQuestions.onSnapshot((doc) => {
      if (
        doc.data() &&
        doc.data()[sectionId] &&
        doc.data()[sectionId].subsections
      ) {
        const subSections = Object.keys(
          doc.data()[sectionId].subsections
            ? doc.data()[sectionId].subsections
            : {}
        ).sort();

        if (!_.isEqual(subSections, assessmentSubsections)) {
          setAssessmentSubsections(subSections ? subSections : {});
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const updateFireStore = (redirect, subSection) => {
    let redirectURL = "";

    switch (redirect) {
      case "back":
        redirectURL = `/${projectName}/cms/${assessmentVersion}/assessment/sections`;
        break;
      case "forward":
        redirectURL = `/${projectName}/cms/${assessmentVersion}/assessment/sections/${encodeURI(
          sectionId
        )}/subSections/${encodeURI(subSection)}`;

        break;

      default:
        break;
    }

    setSaving(true);

    cmsQuestions.update({
      [`${sectionId}.subsections`]: {},
    });

    assessmentSubsections.forEach((assessmentSubsection, subSectionIndex) => {
      if (assessmentSubsection) {
        const scoreKeys = Object.keys(
          assessmentSubsectionsFeedback[subSectionIndex]
        ).sort();

        cmsQuestions.update(
          {
            [`${sectionId}.subsections.${assessmentSubsection}.questions`]: assessmentSubsectionsQuestions[
              subSectionIndex
            ],
          },
          { merge: true }
        );

        scoreKeys.forEach((scoreKey) => {
          cmsQuestions.update(
            {
              [`${sectionId}.subsections.${assessmentSubsection}.feedback.${scoreKey}`]: assessmentSubsectionsFeedback[
                subSectionIndex
              ][scoreKey],
            },
            { merge: true }
          );
        });
      }

      // if (subSectionIndex + 1 >= assessmentSubsections.length) {
      // }
    });

    setTimeout(() => {
      setSaving(false);
      history.push(redirectURL);
    }, 3000);
  };

  const isLiveOrArchived =
    liveVersion === assessmentVersion ||
    archivedVersion.includes(assessmentVersion);

  return (
    <>
      <div className="page">
        <div className="toolbar">
          <div className="toolbar-items">
            <button
              className="secondary back"
              onClick={() => {
                updateFireStore("back");
              }}
              disabled={saving}
            >
              {saving ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    style={{
                      width: "1.25rem",
                      height: "1.25rem",
                      fill: "black",
                      color: "black",
                      marginRight: "0.5rem",
                    }}
                  />
                  Saving
                </div>
              ) : (
                <>
                  <KeyboardArrowLeft />
                  Back and save
                </>
              )}
            </button>
          </div>

          <div className="toolbar-items">
            <h1 className="questions-title">{sectionId}</h1>
          </div>

          <div className="toolbar-items"></div>
        </div>

        <div className="cms-assessment-container">
          {saving ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  fill: "black",
                  color: "black",
                  marginRight: "0.5rem",
                }}
              />
            </div>
          ) : (
            <>
              {assessmentSubsections &&
                assessmentSubsections.map((subSection, subSectionIndex) => {
                  const averageTypes = [
                    "Above average",
                    "Average",
                    "Below average",
                  ];

                  return (
                    <div
                      className="cms-assessment-subSection"
                      key={`subSection-${subSectionIndex}`}
                    >
                      <p className="subtitle">
                        <strong>{subSection}</strong>
                      </p>
                      <label>Title</label>
                      <input
                        value={subSection}
                        onChange={(e) => {
                          if (
                            /^[A-Za-z-\s]*$/gi.test(e.target.value) &&
                            !isLiveOrArchived &&
                            e.target.value.length < 100
                          ) {
                            let items;
                            items = [...assessmentSubsections];
                            let item = { ...items[subSectionIndex] };
                            item = e.target.value;
                            items[subSectionIndex] = item;
                            setAssessmentSubsections(items);
                          }
                        }}
                        disabled={isLiveOrArchived || saving}
                      />

                      <label>Feedback</label>
                      <div className="average-score-container">
                        {averageTypes.map((averageType, averageTypeIndex) => {
                          let feedbackColor = "";
                          switch (averageType) {
                            case "Above average":
                              feedbackColor = "#23BB86";
                              break;

                            case "Average":
                              feedbackColor = "#F2994A";
                              break;

                            case "Below average":
                              feedbackColor = "#D00C00";
                              break;

                            default:
                              break;
                          }

                          return (
                            averageType && (
                              <div
                                key={`feedback-${averageTypeIndex}`}
                                className="average-inner-container"
                                style={{ background: feedbackColor }}
                              >
                                <p className="inner-text subtitle">
                                  <label style={{ color: "white" }}>
                                    {averageType}
                                  </label>
                                  {averageType === "Above average" && (
                                    <ThumbUpOutlined
                                      style={{ fill: "white" }}
                                    />
                                  )}
                                  {averageType === "Below average" && (
                                    <PanToolOutlined
                                      style={{ fill: "white" }}
                                    />
                                  )}
                                  {averageType === "Average" && (
                                    <ErrorOutlineOutlined
                                      style={{ fill: "white" }}
                                    />
                                  )}
                                </p>
                                <textarea
                                  className="feedback-container"
                                  value={
                                    assessmentSubsectionsFeedback &&
                                    assessmentSubsectionsFeedback[
                                      subSectionIndex
                                    ] &&
                                    averageType.toLowerCase() &&
                                    assessmentSubsectionsFeedback[
                                      subSectionIndex
                                    ][averageType.toLowerCase()]
                                  }
                                  disabled={isLiveOrArchived || saving}
                                  label={averageType}
                                  onChange={(e) => {
                                    if (!isLiveOrArchived) {
                                      let items;
                                      items = [
                                        ...assessmentSubsectionsFeedback,
                                      ];
                                      let item = { ...items[subSectionIndex] };
                                      item = {
                                        ...assessmentSubsectionsFeedback[
                                          subSectionIndex
                                        ],
                                        [averageType.toLowerCase()]: e.target
                                          .value,
                                      };

                                      items[subSectionIndex] = item;
                                      setAssessmentSubsectionsFeedback(items);
                                    }
                                  }}
                                />
                              </div>
                            )
                          );
                        })}
                      </div>

                      {subSection && (
                        <button
                          className="secondary"
                          onClick={() => {
                            updateFireStore("forward", subSection);
                          }}
                          disabled={saving}
                        >
                          {saving
                            ? "Saving"
                            : `View ${
                                subSection && subSection.toLowerCase()
                              } questions`}
                        </button>
                      )}

                      {!isLiveOrArchived && (
                        <div className="delete-container">
                          <Cancel
                            onClick={() => {
                              let itemsSubsections;
                              let itemsSubsectionsFeedback;
                              let itemsSubsectionsQuestions;

                              itemsSubsections = [...assessmentSubsections];
                              itemsSubsectionsFeedback = [
                                ...assessmentSubsectionsFeedback,
                              ];
                              itemsSubsectionsQuestions = [
                                ...assessmentSubsectionsQuestions,
                              ];

                              itemsSubsections.splice(subSectionIndex, 1);
                              itemsSubsectionsFeedback.splice(
                                subSectionIndex,
                                1
                              );
                              itemsSubsectionsQuestions.splice(
                                subSectionIndex,
                                1
                              );

                              setAssessmentSubsections(itemsSubsections);
                              setAssessmentSubsectionsFeedback(
                                itemsSubsectionsFeedback
                              );
                              setAssessmentSubsectionsQuestions(
                                itemsSubsectionsQuestions
                              );
                            }}
                            disabled={saving}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}

              {!isLiveOrArchived && (
                <button
                  className="add-another-question-container"
                  onClick={() => {
                    setAssessmentSubsections([...assessmentSubsections, ""]);
                    setAssessmentSubsectionsFeedback([
                      ...assessmentSubsectionsFeedback,
                      {
                        "above average": "",
                        average: "",
                        "below average": "",
                      },
                    ]);
                    setAssessmentSubsectionsQuestions([
                      ...assessmentSubsectionsQuestions,
                      [
                        {
                          body: "",
                          options: [
                            {
                              score: 0,
                              value: "Strongly disagree",
                            },
                            {
                              score: 0,
                              value: "Disagree",
                            },
                            {
                              score: 0,
                              value: "Neither agree nor disagree",
                            },
                            {
                              score: 0,
                              value: "Agree",
                            },
                            {
                              score: 0,
                              value: "Strongly agree",
                            },
                          ],
                        },
                      ],
                    ]);
                  }}
                >
                  <p className="add-another-question-content">
                    <Add />
                    Add another subsection
                  </p>
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(CMSAssessmentSubsections);
