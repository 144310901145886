import React, { memo } from "react";
import PropTypes from "prop-types";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const Questions = (props) => {
  const {
    options,
    category,
    helperText,
    body,
    setAnswer,
    answer,
    isAssessment,
    section,
    subSection,
  } = props;

  const useStyles = makeStyles({
    groupRoot: {
      background: "#FFFFFF",
      border: "solid 1.5px #C0C0C0",
      boxSizing: "border-box",
      borderRadius: "12px",
      margin: "0.5rem 0",
      padding: "0.5rem",
    },
    groupRootActive: {
      background: "rgba(77, 113, 247, 0.06);",
      border: "2px solid #4D71F7",
      boxSizing: "border-box",
      borderRadius: "12px",
      margin: "0.5rem 0",
      padding: "0.5rem",
    },
    labelRoot: {
      margin: 0,
    },
    labelLabel: {
      fontFamily: "HK Grotesk",
      fontSize: "20px",
      fontWeight: 500,
      color: "#171717",
    },
    radioButton: {
      color: "#C0C0C0",
      "&$checked": {
        color: "#4D71F7",
      },
    },
    checked: {
      color: "#4D71F7",
    },
  });

  const classes = useStyles(props);

  return (
    <>
      <h2 className="title">{body}</h2>

      <p className="body">{helperText}</p>

      <div className="questions">
        {options.map(({ value, score }, index) => {
          let match = false;

          if (isAssessment) {
            match =
              Number.isInteger(answer) &&
              Number.isInteger(score) &&
              answer === score;
          } else {
            match = answer && value && answer === value;
          }

          return (
            options.length > 0 && (
              <RadioGroup
                classes={{
                  root: match ? classes.groupRootActive : classes.groupRoot,
                }}
                key={`question-${index}`}
                aria-label={category}
                name={category}
                value={answer}
                onChange={(e) => {
                  const assessmentObject = {
                    section: section,
                    subSection: subSection,
                    score: parseInt(e.target.value),
                  };

                  const onboardingObject = {
                    value: e.target.value,
                    question: body,
                  };

                  setAnswer(isAssessment ? assessmentObject : onboardingObject);
                }}
              >
                <FormControlLabel
                  classes={{
                    root: classes.labelRoot,
                    label: classes.labelLabel,
                  }}
                  value={!!!!isAssessment ? score : value}
                  control={
                    <Radio
                      color="primary"
                      classes={{
                        root: classes.radioButton,
                        checked: classes.checked,
                      }}
                      tabIndex={index}
                    />
                  }
                  label={
                    value &&
                    value.charAt(0).toUpperCase() + value.toLowerCase().slice(1)
                  }
                />
              </RadioGroup>
            )
          );
        })}
      </div>
    </>
  );
};

Questions.propTypes = {
  nextQuestionId: PropTypes.number,
  previousQuestionId: PropTypes.number,
  nextQuestion: PropTypes.func,
  previousQuestion: PropTypes.func,
};

Questions.defaultProps = {
  nextQuestionId: 1,
  previousQuestionId: 1,
  nextQuestion: () => {},
  previousQuestion: () => {},
};

export default memo(Questions);
