import React, { useEffect, useState } from "react";
import { Cancel, Add, Done } from "@material-ui/icons";
import firebase from "../../firebase";
import {
  Snackbar,
  SnackbarContent,
  CircularProgress,
  Modal,
  Tooltip,
} from "@material-ui/core";
import { KeyboardArrowLeft, GroupAdd, PersonAdd } from "@material-ui/icons";
import _ from "lodash";

const UsersAdd = ({
  currentUserEmail,
  history,
  match,
  loading,
  setLoading,
  modalOpen,
  setModalOpen,
  addGroup,
  setAddGroup,
}) => {
  const [errors, setErrors] = useState([
    {
      company: "",
      email: "",
      name: "",
    },
  ]);

  const { projectName } = match.params;

  const [users, setUsers] = useState([
    {
      company: "",
      email: "",
      name: "",
    },
  ]);
  const [showAreYouSure, setShowAreYouSure] = useState([false]);
  const [buttonText, setButtonText] = useState("Add another user");
  const [alerts, setAlerts] = useState([]);

  const maximumSingleUsersAdd = 20;
  const limitExceedText = "Exceeded maximum singular user add";
  const limitNotExceededText = "Add another user";

  useEffect(() => {
    if (
      users.length >= maximumSingleUsersAdd &&
      buttonText !== limitExceedText
    ) {
      setButtonText(limitExceedText);
    } else if (
      users.length < maximumSingleUsersAdd &&
      buttonText !== limitNotExceededText
    ) {
      setButtonText(limitNotExceededText);
    }
  }, [buttonText, users]);

  const setError = (errorMessage, index, name) => {
    let items;
    items = [...errors];
    let item = { ...items[index] };
    const newError = { [name]: errorMessage };
    item = {
      ...items[index],
      ...newError,
    };
    items[index] = item;
    setErrors(items);
  };

  const setShowSure = (index, value) => {
    let items;
    items = [...showAreYouSure];
    let item = { ...items[index] };
    item = value;
    items[index] = item;
    setShowAreYouSure(items);
  };

  const setUser = (user, index) => {
    let items;
    items = [...users];
    let item = { ...items[index] };
    item = user;
    items[index] = item;
    setUsers(items);
  };

  const addUsers = () => {
    const addUserCloudFunction = firebase.functions().httpsCallable("addUser");

    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        /**
         * CHECK CURRENT USER IS ADMIN
         */
        const {
          claims: { isAdmin },
        } = idTokenResult;

        if (isAdmin) {
          users.forEach((user, index) => {
            const { company, email, name } = user;

            if (
              validate(index, name, "name") &&
              validate(index, company, "company") &&
              validate(index, email, "email") &&
              passValidation()
            ) {
              const db = firebase.firestore();
              const allusers = db
                .collection(projectName)
                .doc("users")
                .collection("user")
                .doc("all")
                .get();

              allusers.then((doc) => {
                const nonAdminEmails =
                  typeof doc.data() === "object"
                    ? Object.values(doc.data()).map(({ email }) => {
                        return email;
                      })
                    : [];

                const userNotExist =
                  !nonAdminEmails.includes(email) && email !== currentUserEmail;

                if (userNotExist) {
                  if (users.length === index + 1) {
                    const invitedEmails = users.map(({ email }) => {
                      return `${email} invited`;
                    });

                    const usersData = users.map((user) => {
                      return {
                        company: "",
                        email: "",
                        name: "",
                      };
                    });

                    setAlerts(invitedEmails);
                    setErrors(usersData);
                    setUsers(usersData);
                    setShowAreYouSure([false]);
                    setTimeout(() => {
                      setAlerts([]);
                    }, 5000);
                  }

                  setLoading(true);
                  addUserCloudFunction({
                    email,
                    name,
                  })
                    .then((user) => {
                      const uid = user.data;
                      setLoading(false);

                      if (uid) {
                        const db = firebase.firestore();
                        const users = db
                          .collection(projectName)
                          .doc("users")
                          .collection("user")
                          .doc(String(uid));
                        const allusers = db
                          .collection(projectName)
                          .doc("users")
                          .collection("user")
                          .doc("all");

                        allusers.set(
                          {
                            [String(uid)]: {
                              uid,
                              email,
                              emailVerified: true,
                              displayName: name,
                              isUser: true,
                              company,
                              joined: true,
                              status: true,
                              pendingResults: true,
                            },
                          },
                          { merge: true }
                        );

                        users.set(
                          {
                            uid,
                            email,
                            emailVerified: true,
                            displayName: name,
                            isUser: true,
                            company,
                            joined: true,
                            status: true,
                            pendingResults: true,
                          },
                          { merge: true }
                        );
                        /**
                         * SEND EMAIL LINK
                         */

                        const sendEmailLinkInvitation = firebase
                          .functions()
                          .httpsCallable("sendEmailLink");

                        sendEmailLinkInvitation({ email, projectName, name })
                          .then(() => {
                            console.log("Magic link has been sent!");
                          })
                          .catch((error) => {
                            console.error(error);
                          });
                      }
                    })
                    .catch((e) => {
                      console.error(`error message: ${e.message}`);
                    });
                } else {
                  setError("User already exists!", index, "email");
                }
              });
            }
          });
        } else {
          throw new Error("Not authorised to perform request");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const validate = (index, value, name) => {
    if (!value) {
      setError(`Please input ${name}`, index, name);
      return false;
    } else {
      let validationRegex;
      let error = "";

      switch (name) {
        case "email":
          validationRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          error = "Please check email address is formatted correctly";
          break;

        case "company":
          validationRegex = /^[A-Za-z\s0-9]+$/;
          error = "Please only use alphanumeric characters";
          break;

        case "name":
          validationRegex = /^[A-Za-z-\s]*$/;
          error = "Please remove any non alphabetic characters";
          break;

        default:
          break;
      }

      const passingValidation = validationRegex.test(
        String(value).toLowerCase()
      );

      setError(passingValidation ? "" : error, index, name);
      return passingValidation;
    }
  };

  const passValidation = () => {
    const errorsArray = errors.map((error) => {
      return Object.values(error).map((err) => {
        return err ? true : false;
      });
    });

    const includesError = errorsArray.includes(true);

    return !includesError;
  };

  return (
    <>
      <div className="toolbar">
        <div className="toolbar-items">
          <button
            className="secondary back"
            onClick={() => {
              if (!_.isEqual(users, [{ company: "", email: "", name: "" }])) {
                setModalOpen(true);
              } else {
                history.push(`/${projectName}/users`);
              }
            }}
            disabled={loading}
          >
            <KeyboardArrowLeft />
            Back
          </button>
        </div>

        <div className="toolbar-items">
          <h1 className="users-title">
            {addGroup ? "Add group of users" : "Add user"}
          </h1>
        </div>

        <div className="toolbar-items">
          {!addGroup ? (
            <Tooltip title="Add group of users" aria-label="Add group of users">
              <button
                className="tertiary action-button"
                onClick={() => setAddGroup(true)}
                disabled={false}
              >
                <GroupAdd />
              </button>
            </Tooltip>
          ) : (
            <Tooltip title="Add user" aria-label="Add user">
              <button
                className="tertiary action-button"
                onClick={() => setAddGroup(false)}
                disabled={false}
              >
                <PersonAdd />
              </button>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="add-user-container">
        {users.map(({ company, email, name }, index) => {
          return (
            <div className="user-container" key={index}>
              <h2>{name ? name : "Unnamed"}</h2>
              <div className="user-inputs">
                <div>
                  <label
                    className={`${
                      errors[index] && errors[index].name ? "error" : ""
                    }`}
                  >
                    Name
                  </label>
                  <input
                    maxLength="35"
                    value={name}
                    label="Name"
                    onChange={(e) => {
                      setUser(
                        {
                          company: company ? company : "",
                          email: email ? email : "",
                          name: e.target.value,
                        },
                        index
                      );
                    }}
                    onBlur={(e) => validate(index, e.target.value, "name")}
                    style={{
                      border:
                        errors[index] && errors[index].name
                          ? "red solid 1px"
                          : "",
                    }}
                  />
                </div>

                <div>
                  <label
                    className={`${
                      errors[index] && errors[index].company ? "error" : ""
                    }`}
                  >
                    Company
                  </label>
                  <input
                    maxLength="35"
                    value={company}
                    label="Company"
                    onChange={(e) => {
                      setUser(
                        {
                          company: e.target.value,
                          email: email ? email : "",
                          name: name ? name : "",
                        },
                        index
                      );
                    }}
                    onBlur={(e) => validate(index, e.target.value, "company")}
                    style={{
                      border:
                        errors[index] && errors[index].company
                          ? "red solid 1px"
                          : "",
                    }}
                  />
                </div>

                <div>
                  <label
                    className={`${
                      errors[index] && errors[index].email ? "error" : ""
                    }`}
                  >
                    Email
                  </label>
                  <input
                    maxLength="60"
                    value={email}
                    label="Email address"
                    onChange={(e) => {
                      setUser(
                        {
                          company: company ? company : "",
                          email: e.target.value,
                          name: name ? name : "",
                        },
                        index
                      );
                    }}
                    onBlur={(e) => validate(index, e.target.value, "email")}
                    style={{
                      border:
                        errors[index] && errors[index].email
                          ? "red solid 1px"
                          : "",
                    }}
                  />
                </div>
              </div>

              {(errors[index].name ||
                errors[index].company ||
                errors[index].email) && (
                <div className="error-container">
                  <p className="error">
                    {errors[index].name && errors[index].name}
                  </p>
                  <p className="error">
                    {errors[index].company && errors[index].company}
                  </p>
                  <p className="error">
                    {errors[index].email && errors[index].email}
                  </p>
                </div>
              )}

              {index > 0 && (
                <div className="delete-container">
                  {showAreYouSure[index] && <p>Are you sure?</p>}
                  <Cancel
                    onClick={() => {
                      setShowSure(index, true);

                      if (showAreYouSure[index]) {
                        let items;
                        let errorItems;
                        let showAreYouSureItems;
                        items = [...users];
                        errorItems = [...errors];
                        showAreYouSureItems = [...showAreYouSure];

                        if (index > -1) {
                          items.splice(index, 1);
                          errorItems.splice(index, 1);
                          showAreYouSureItems.splice(index, 1);
                        }

                        setUsers(items);
                        setErrors(errorItems);
                        setShowAreYouSure(showAreYouSureItems);
                      }
                    }}
                    disabled={false}
                  />
                </div>
              )}
            </div>
          );
        })}
        <button
          className={`add-another-user-container ${
            buttonText === limitExceedText ? "error" : ""
          } `}
          onClick={() => {
            if (users.length < maximumSingleUsersAdd) {
              setErrors([...errors, { company: "", email: "", name: "" }]);
              setUsers([...users, { company: "", email: "", name: "" }]);
              setShowAreYouSure([...showAreYouSure, false]);
              setButtonText(limitNotExceededText);
            }
          }}
          disabled={users.length >= maximumSingleUsersAdd}
        >
          <p
            className={`add-another-user-content ${
              buttonText === limitExceedText ? "error" : ""
            } `}
          >
            <Add />
            {buttonText}
          </p>
        </button>

        <div className="save-container">
          <button
            className="primary"
            onClick={() => addUsers()}
            disabled={false}
          >
            {loading ? (
              <div style={{ paddingRight: "0.5rem" }}>
                <CircularProgress
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                    fill: "white",
                    color: "white",
                  }}
                />
              </div>
            ) : (
              <Done />
            )}
            Save and send invite
          </button>
        </div>

        {alerts && (
          <Snackbar
            open={true}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <div>
              {alerts.length > 0 &&
                alerts.map((alert, index) => (
                  <SnackbarContent
                    message={alert}
                    key={`snack${index}`}
                    style={{ marginBottom: "1rem" }}
                  />
                ))}
            </div>
          </Snackbar>
        )}

        <Modal
          open={modalOpen}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="modal-container">
            <div className="modal-content">
              <h2>Discard unsaved changes?</h2>
              <p></p>
              <div className="cta-container">
                <button
                  className="tertiary action-button"
                  onClick={() => {
                    setModalOpen(false);
                  }}
                  disabled={false}
                >
                  Cancel
                </button>
                <button
                  className="tertiary action-button"
                  onClick={() => {
                    setModalOpen(false);
                    setUsers([{ company: "", email: "", name: "" }]);
                    setErrors([{ company: "", email: "", name: "" }]);
                    setShowAreYouSure([false]);
                    history.push(`/${projectName}/users`);
                  }}
                  disabled={false}
                >
                  Discard
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default UsersAdd;
