import React, { useState } from "react";
import cookie from "../../assets/icons/cookie.svg";
import { KeyboardArrowUp, KeyboardArrowLeft } from "@material-ui/icons";
import { FooterV2 } from "../../components";

const CookiePolicy = (props) => {
  const { history, match } = props;
  const [showScroll, setShowScroll] = useState(false);
  const { projectName } = match.params;

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  const hideLinks =
    match.url.includes("faq") ||
    match.url.includes("cookie-policy") ||
    match.url.includes("privacy-policy");

  return (
    <>
      <div className="page">
        <button
          className="secondary back"
          onClick={() => history.goBack()}
          disabled={false}
        >
          <KeyboardArrowLeft />
          Back
        </button>
        <div className="cookie-policy">
          <img src={cookie} alt="cookie icon" />
          <h1 className="title">Cookie Policy </h1>
          <p className="sub-title subtitle ">
            <strong>
              This section sets out how we use cookies. By using our website you
              are confirming your consent to the use of cookies as explained in
              this Policy.
            </strong>
          </p>
          <div className="body">
            <p className="subtitle">
              <strong>What are cookies?</strong>
            </p>
            <p>
              Cookies are widely used to allow online and mobile sites to
              function efficiently and are often small data files that
              (depending on your browser settings) are deposited on your
              computer, mobile phone, tablet or other device when you visit a
              site. The cookies may be sent back to that site when you visit
              again, and may then be used by the server to identify and track
              your use of our website
            </p>
            <p className="subtitle">
              <strong>We may use some or all of the following cookies:</strong>
            </p>
            <ul>
              <li>
                <p className="subtitle">
                  <strong>Strictly necessary cookies</strong>
                </p>
                <p>
                  These are cookies that are required for the operation of our
                  website.
                </p>
              </li>
              <li>
                <p className="subtitle">
                  <strong>Analytical/performance cookies</strong>
                </p>
                <p>
                  They allow us to recognise and count the number of visitors
                  and to see how visitors move around our website when they are
                  using it. This helps us to improve the way our website works,
                  for example, by ensuring that users are finding what they are
                  looking for easily.
                </p>
              </li>
              <li>
                <p className="subtitle">
                  <strong>Functionality cookies</strong>
                </p>
                <p>
                  These are used to recognise you when you return to our
                  website. This may enable us to personalise our content for
                  you, greet you by name and remember your preferences (for
                  example, your choice of language or region).
                </p>
              </li>
              <li>
                <p className="subtitle">
                  <strong>Targeting cookies</strong>
                </p>
                <p>
                  These cookies record your visit to our website, the pages you
                  have visited and the links you have followed. We will use this
                  information to make our website and the advertising displayed
                  (if any) on it more relevant to your interests.
                </p>
              </li>
              <li>
                <p className="subtitle">
                  <strong>Third party cookies</strong>
                </p>
                <p>
                  In some special cases cookies are also provided by third
                  parties (including, for example, advertising networks and
                  providers of external services like web traffic analysis
                  services) over which we have no control. These cookies are
                  likely to be analytical/performance cookies or targeting
                  cookies.
                </p>
              </li>
              <li>
                <p className="subtitle">
                  <strong>Disabling cookies</strong>
                </p>
                <p>
                  If you object to the use of cookies, most browsers can be
                  configured to alert you to their use or to enable you to
                  reject browser-based cookies (see your browser help menu for
                  how to do this. Third parties may use certain special types of
                  cookies (such as flash cookies) that cannot be disabled by
                  configuring browsers, although other methods of preventing or
                  managing such cookies may be available to you (such as via the
                  relevant third-party settings panel). You can also delete
                  cookies already stored on your device.
                </p>
              </li>
            </ul>
            <p className="further-information">
              <strong>
                Further information on rejection and deletion of cookies is
                provided at{" "}
                <a href="www.aboutcookies.org">www.aboutcookies.org</a>
              </strong>
            </p>
          </div>
          <button
            className="secondary see-more"
            onClick={scrollTop}
            disabled={false}
          >
            <KeyboardArrowUp />
            Go to the top
          </button>
        </div>
      </div>
      <FooterV2 projectName={projectName} hideLinks={hideLinks} />
    </>
  );
};

export default CookiePolicy;
