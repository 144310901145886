import React, { memo } from "react";

import { EditQuestionRadioGroup } from "../../components";

import { Cancel, Add } from "@material-ui/icons";

const EditQuestions = ({
  assessmentQuestions,
  setAssessmentQuestions,
  setAssessmentQuestionOptions,
  assessmentQuestionOptions,
  disabled,
}) => {
  return (
    <>
      <div className="add-question-container">
        {assessmentQuestions &&
          assessmentQuestions.length > 0 &&
          assessmentQuestions.map(({ body }, questionIndex) => {
            return (
              <div
                className="question-container"
                key={`question-${questionIndex}`}
              >
                <div className="question-inputs">
                  <div>
                    <p style={{ marginTop: 0 }}>
                      <strong>Question {questionIndex + 1}</strong>
                    </p>
                    <label>Body</label>

                    <input
                      value={body}
                      onChange={(e) => {
                        if (!disabled) {
                          let items;
                          items = [...assessmentQuestions];
                          let item = { ...items[questionIndex] };
                          item = {
                            body: e.target.value,
                          };
                          items[questionIndex] = item;
                          setAssessmentQuestions(items);
                        }
                      }}
                      disabled={disabled}
                    />
                  </div>

                  <div className="options-container">
                    {assessmentQuestionOptions.length > 0 &&
                      assessmentQuestionOptions[questionIndex].options.map(
                        ({ value, score }, optionIndex) => {
                          return (
                            <EditQuestionRadioGroup
                              value={value}
                              score={score}
                              optionIndex={optionIndex}
                              options={
                                assessmentQuestionOptions[questionIndex].options
                              }
                              assessmentQuestionOptions={
                                assessmentQuestionOptions
                              }
                              setAssessmentQuestionOptions={
                                setAssessmentQuestionOptions
                              }
                              disabled={disabled}
                              questionIndex={questionIndex}
                            />
                          );
                        }
                      )}
                  </div>

                  {!disabled && (
                    <div className="delete-question-container">
                      <Cancel
                        onClick={() => {
                          let items;
                          let optionsItems;
                          items = [...assessmentQuestions];
                          optionsItems = [...assessmentQuestionOptions];

                          items.splice(questionIndex, 1);
                          optionsItems.splice(questionIndex, 1);
                          setAssessmentQuestions(items);
                          setAssessmentQuestionOptions(optionsItems);
                        }}
                        disabled={false}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>

      {!disabled && (
        <button
          className="add-another-question-container"
          onClick={() => {
            setAssessmentQuestions([
              ...assessmentQuestions,
              {
                body: "",
              },
            ]);
            setAssessmentQuestionOptions([
              ...assessmentQuestionOptions,
              {
                options: [
                  { value: "Strongly disagree", score: 0 },
                  { value: "Disagree", score: 0 },
                  { value: "Neither agree nor disagree", score: 0 },
                  { value: "Agree", score: 0 },
                  { value: "Strongly agree", score: 0 },
                ],
              },
            ]);
          }}
        >
          <p className="add-another-question-content">
            <Add />
            Add another question
          </p>
        </button>
      )}
    </>
  );
};

export default memo(EditQuestions);
