import React, { memo } from "react";
import PropTypes from "prop-types";

const Feedback = (props) => {
  const { closeFeedbackMessage, buttonText, icon, children } = props;

  return (
    <div className="feedback-container">
      <div className="feedback-message">
        {icon && <img alt="Good job icon" src={icon} />}
        {children}
        {buttonText && closeFeedbackMessage && (
          <button className="primary" onClick={closeFeedbackMessage}>
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

Feedback.propTypes = {
  icon: PropTypes.string,
  closeFeedbackMessage: PropTypes.func,
  buttonText: PropTypes.string,
};

Feedback.defaultProps = {
  icon: "",
  closeFeedbackMessage: () => {},
  buttonText: "",
};

export default memo(Feedback);
