import React from "react";
import PropTypes from "prop-types";

import {
  TableHead,
  TableCell,
  TableSortLabel,
  TableRow,
  Checkbox,
} from "@material-ui/core";

const UsersTableHeader = (props) => {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const headCells = [
    { id: "displayName", numeric: false, label: "Name" },
    { id: "email", numeric: false, label: "Email" },
    { id: "company", numeric: false, label: "Company" },
    { id: "pendingResults", numeric: false, label: "Assessment status" },
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow classes={{ root: classes.headerRowRoot }}>
        <TableCell padding="checkbox" classes={{ root: classes.cellRoot }}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            style={{
              color: "#4D71F7",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id === "pendingResults" ? "center" : "left"}
            padding={"default"}
            sortDirection={orderBy === headCell.id ? order : false}
            classes={{ root: classes.cellRoot }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              classes={{ root: classes.cellRootLabel }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

UsersTableHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default UsersTableHeader;
