import React, { memo } from "react";

import { Summary, ResultsSection } from "../../components";

const Results = (props) => {
  const {
    activeSection,
    sectionsNormGroup,
    selectedUsers,
    subSectionsFeedback,
    isUserReport,
    userAssessmentData,
  } = props;

  return (
    <>
      {activeSection !== "summary" ? (
        <ResultsSection
          activeSection={activeSection}
          sectionsNormGroup={sectionsNormGroup}
          selectedUsers={selectedUsers}
          subSectionsFeedback={subSectionsFeedback}
          isUserReport={isUserReport}
          userAssessmentData={userAssessmentData}
        />
      ) : (
        <div className="section-summary-container">
          <Summary
            sectionsNormGroup={sectionsNormGroup}
            selectedUsers={selectedUsers}
            isUserReport={isUserReport}
            userAssessmentData={userAssessmentData}
          />
        </div>
      )}
    </>
  );
};

export default memo(Results);
