import React, { useEffect, useState, useCallback } from "react";
import {
  Questions,
  ProgressBar,
  Footer,
  AssessmentFeedback,
} from "../../components";
import firebase from "../../firebase";
import _ from "lodash";
import { currentSection, shuffle, msToTime } from "../../utils";
import ReactGA from "react-ga";

const Assessment = ({ sendValuesToParent, match, history, user }) => {
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const [assessmentAnswers, setAssessmentAnswers] = useState([]);
  const [sectionCompletedVal, setSectionCompletedVal] = useState(false);
  const [liveVersion, setLiveVersion] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);

  const { projectName } = match.params;

  const onExit = useCallback((event) => {
    event.preventDefault();

    return (event.returnValue = "Are you sure you want to close?");
  }, []);

  useEffect(() => {
    setTimeStamp(Date.now());
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", onExit);

    return () => {
      window.removeEventListener("beforeunload", (ev) => onExit);
    };
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            /**
             * CHECK CURRENT USER IS ADMIN
             */
            const { claims } = idTokenResult;

            if (claims.isAdmin) {
              history.push(`/${projectName}/users`);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });
  }, [history]);

  useEffect(() => {
    const db = firebase.firestore();
    const liveAssessmentVersion = db
      .collection(projectName)
      .doc("cms")
      .collection("published")
      .doc("live");

    const unsubscribe = liveAssessmentVersion.onSnapshot((doc) => {
      if (!_.isEqual(liveVersion, doc.data().version)) {
        setLiveVersion(doc.data().version);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (liveVersion) {
      const db = firebase.firestore();
      const currentAssesmentVersion = db
        .collection(projectName)
        .doc("cms")
        .collection("version")
        .doc(liveVersion)
        .collection("assessment")
        .doc("sections")
        .get();

      currentAssesmentVersion
        .then((doc) => {
          let emptyAnswersArray = [];
          const sectionsKey = Object.keys(doc.data()).sort();
          const questions = [];

          sectionsKey.forEach((sectionKey) => {
            return Object.keys(doc.data()[sectionKey].subsections)
              .sort()
              .forEach((subsectionKey) => {
                doc
                  .data()
                  [sectionKey].subsections[subsectionKey].questions.forEach(
                    ({ body, options }) => {
                      questions.push({
                        body,
                        options,
                        section: sectionKey,
                        subSection: subsectionKey,
                      });
                    }
                  );
              });
          });

          emptyAnswersArray = questions.map(() => {
            return {
              section: "",
              subSection: "",
              score: null,
            };
          });

          if (localStorage.getItem(liveVersion)) {
            const shuffledQuestionsStorage = JSON.parse(
              localStorage.getItem(liveVersion)
            );
            setAssessmentQuestions(shuffledQuestionsStorage);
            console.log("Randomised questions retrieved from local storage");
          } else {
            const shuffledQuestions = shuffle(questions);
            localStorage.setItem(
              liveVersion,
              JSON.stringify(shuffledQuestions)
            );
            console.log("Randomised questions saved to local storage");
            setAssessmentQuestions(shuffledQuestions);
          }

          setAssessmentAnswers(emptyAnswersArray);
        })
        .catch((e) => {
          console.error(`error message: ${e.message}`);
        });
    }
  }, [liveVersion]);

  const changeAnswers = (data, index, page) => {
    let items;
    items = [...assessmentAnswers];
    let item = { ...items[index] };
    item = data;
    items[index] = item;
    setAssessmentAnswers(items);
  };

  const nextAssessmentQuestion = () => {
    const percent25 = Math.round(assessmentQuestions.length * 0.25);
    const percent50 = Math.round(assessmentQuestions.length * 0.5);
    const percent75 = Math.round(assessmentQuestions.length * 0.75);
    const percent100 = assessmentQuestions.length;
    const currentQuestion = activeQuestion + 1;

    const sectionCompleted =
      currentQuestion === percent25 ||
      currentQuestion === percent50 ||
      currentQuestion === percent75 ||
      currentQuestion === percent100;

    if (sectionCompleted) {
      setSectionCompletedVal(sectionCompleted);
    }

    ReactGA.event({
      category: projectName,
      action: `Question ${activeQuestion} completed`,
      label: assessmentQuestions[activeQuestion],
      value: Date.now() - timeStamp,
    });

    ReactGA.timing({
      category: projectName,
      variable: `Time taken for Question ${activeQuestion} completed`,
      label: assessmentQuestions[activeQuestion],
      value: Date.now() - timeStamp,
    });

    setTimeStamp(Date.now());
    setActiveQuestion(currentQuestion);

    if (currentQuestion >= assessmentQuestions.length) {
      console.log("assessment sent!!!");

      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then(() => {
          const db = firebase.firestore();
          const assessmentTimestampStart = parseInt(
            localStorage.getItem("assessmentTimestampStart")
          );

          const assessments = db
            .collection(projectName)
            .doc("assessments")
            .collection(liveVersion)
            .doc(user.uid);
          const allAssessments = db
            .collection(projectName)
            .doc("assessments")
            .collection(liveVersion)
            .doc("all");
          const currentTime = Date.now();

          assessments.set(
            {
              [assessmentTimestampStart]: {
                assessmentTimestampStart: assessmentTimestampStart,
                assessmentTimestampEnd: currentTime,
              },
            },
            { merge: true }
          );

          const grouped = _.groupBy(
            assessmentAnswers.length > 0 && assessmentAnswers,
            ({ subSection }) => {
              return subSection;
            }
          );

          const averageScoreOfSubSection = Object.keys(grouped)
            .sort()
            .map((answers) => {
              let totalSum = 0;

              grouped[answers].forEach(({ score }) => {
                totalSum += score;
              });

              const average = totalSum / grouped[answers].length;

              return {
                section: grouped[answers][0].section,
                subSection: answers,
                averageScore: Math.round(average * 10) / 10,
              };
            });

          averageScoreOfSubSection.forEach(
            ({ section, subSection, averageScore }) => {
              allAssessments.set(
                {
                  assessment: {
                    [section]: {
                      [subSection]: {
                        [String(user.uid)]: averageScore,
                      },
                    },
                  },
                },
                { merge: true }
              );

              assessments.set(
                {
                  [assessmentTimestampStart]: {
                    assessment: {
                      [section]: {
                        [subSection]: averageScore,
                      },
                    },
                  },
                },
                { merge: true }
              );
            }
          );

          const users = db
            .collection(projectName)
            .doc("users")
            .collection("user")
            .doc(String(user.uid));

          const allusers = db
            .collection(projectName)
            .doc("users")
            .collection("user")
            .doc("all");

          allusers.set(
            {
              [String(user.uid)]: {
                pendingResults: false,
              },
            },
            { merge: true }
          );

          users.set(
            {
              pendingResults: false,
            },
            { merge: true }
          );
        })
        .catch((err) => {
          console.error(err);
        });

      const completedAssessmentCloudFunction = firebase
        .functions()
        .httpsCallable("completedAssessment");

      completedAssessmentCloudFunction({
        uid: user.uid,
      });
    }
  };

  const previousAssessmentQuestion = () => {
    setActiveQuestion(activeQuestion - 1);
  };

  const closeFeedbackMessage = () => {
    setSectionCompletedVal(false);
  };

  const completedAssessment = () => {
    setSectionCompletedVal(false);
    const currentQuestion = activeQuestion + 1;
    const assessmentTimestampStart = parseInt(
      localStorage.getItem("assessmentTimestampStart")
    );

    if (currentQuestion > assessmentQuestions.length) {
      history.push(
        `/user/${user.uid}/${projectName}/${assessmentTimestampStart}`
      );

      console.log("removing local storage data!");
      localStorage.removeItem("assessmentTimestampStart");
      localStorage.removeItem(liveVersion);
    }
  };

  return (
    <>
      <div className="assessment">
        {assessmentQuestions.length > 0 && (
          <div>
            <ProgressBar
              activeQuestion={activeQuestion + 1}
              questions={assessmentQuestions}
              sectionCompleted={sectionCompletedVal}
              sectionCompletedVal={sectionCompletedVal}
              currentSection={currentSection(
                activeQuestion,
                assessmentQuestions
              )}
              sectionsRequired={4}
            />

            {sectionCompletedVal ? (
              <AssessmentFeedback
                activeQuestion={activeQuestion}
                assessmentQuestions={assessmentQuestions}
                closeFeedbackMessage={closeFeedbackMessage}
                completedAssessment={completedAssessment}
              />
            ) : (
              <>
                {assessmentQuestions.map((question, index) => {
                  return (
                    index === activeQuestion && (
                      <Questions
                        options={question.options}
                        helperText={
                          "None of the following questions will be used to identify you. All of your answers will be kept strictly confidential and will only be used in aggregate."
                        }
                        body={question.body}
                        section={question.section}
                        subSection={question.subSection}
                        key={`assessment-${index}`}
                        questionIndex={index}
                        isAssessment={true}
                        setAnswer={(answersObject) => {
                          changeAnswers(answersObject, index, "assessment");
                        }}
                        answer={
                          assessmentAnswers &&
                          assessmentAnswers[index] &&
                          Number.isInteger(assessmentAnswers[index].score)
                            ? assessmentAnswers[index].score
                            : null
                        }
                      />
                    )
                  );
                })}
              </>
            )}
          </div>
        )}
      </div>

      {!sectionCompletedVal && (
        <Footer
          answers={assessmentAnswers}
          activeQuestion={activeQuestion}
          nextQuestion={nextAssessmentQuestion}
          previousQuestion={previousAssessmentQuestion}
          isAssessment={true}
          numberOfQuestions={assessmentQuestions.length}
        />
      )}
    </>
  );
};

export default Assessment;
