import React, { useState } from "react";
import { Done, Info } from "@material-ui/icons";
import firebase from "../../firebase";
import {
  Snackbar,
  SnackbarContent,
  CircularProgress,
  Modal,
  Tooltip,
} from "@material-ui/core";
import { KeyboardArrowLeft, GroupAdd, PersonAdd } from "@material-ui/icons";
import _ from "lodash";

const UsersGroupAdd = ({
  currentUserEmail,
  history,
  projectName,
  loading,
  setLoading,
  modalOpen,
  setModalOpen,
  addGroup,
  setAddGroup,
}) => {
  const [groupCompany, setGroupCompany] = useState("");
  const [groupEmails, setGroupEmails] = useState("");
  const [alerts, setAlerts] = useState([]);
  const [errors, setErrors] = useState({
    groupCompany: "",
    groupEmails: "",
  });

  const addUsers = () => {
    const addUserCloudFunction = firebase.functions().httpsCallable("addUser");
    const db = firebase.firestore();
    const arrayOfGroupEmails = groupEmails
      .replace(/\s/g, "")
      .split(",")
      .filter((el) => el != null);
    const allusers = db
      .collection(projectName)
      .doc("users")
      .collection("user")
      .doc("all")
      .get();

    groupEmails.length > 0 &&
      arrayOfGroupEmails.forEach((email, index) => {
        allusers.then((doc) => {
          const allusersEmails =
            typeof doc.data() === "object"
              ? Object.values(doc.data()).map(({ email }) => {
                  return email;
                })
              : [];

          const userNotExist =
            !allusersEmails.includes(email) && email !== currentUserEmail;

          if (
            !errors.groupCompany &&
            !errors.groupEmails &&
            validate(groupCompany, "groupCompany") &&
            validate(arrayOfGroupEmails, "groupEmails")
          ) {
            if (userNotExist) {
              if (index + 1 === arrayOfGroupEmails.length) {
                const invitedEmails = arrayOfGroupEmails.map((email) => {
                  return `${email} invited`;
                });

                setAlerts(invitedEmails);
                setErrors([
                  {
                    groupCompany: "",
                    groupEmails: "",
                  },
                ]);
                setGroupEmails("");
                setGroupCompany("");

                setTimeout(() => {
                  setAlerts([]);
                }, 5000);
              }

              if (index === 0) {
                setLoading(true);
              }

              addUserCloudFunction({
                email,
                name: "",
              })
                .then((user) => {
                  if (index + 1 === arrayOfGroupEmails.length) {
                    setLoading(false);
                  }
                  const uid = user.data;

                  if (uid) {
                    const db = firebase.firestore();
                    const users = db
                      .collection(projectName)
                      .doc("users")
                      .collection("user")
                      .doc(String(uid));
                    const allusers = db
                      .collection(projectName)
                      .doc("users")
                      .collection("user")
                      .doc("all");

                    allusers.set(
                      {
                        [String(uid)]: {
                          uid,
                          email,
                          emailVerified: true,
                          displayName: "",
                          isUser: true,
                          company: groupCompany,
                          joined: true,
                          status: true,
                          pendingResults: true,
                        },
                      },
                      { merge: true }
                    );

                    users.set(
                      {
                        uid,
                        email,
                        emailVerified: true,
                        displayName: "",
                        isUser: true,
                        company: groupCompany,
                        joined: true,
                        status: true,
                        pendingResults: true,
                      },
                      { merge: true }
                    );
                    /**
                     * SEND EMAIL LINK
                     */

                    const sendEmailLinkInvitation = firebase
                      .functions()
                      .httpsCallable("sendEmailLink");

                    sendEmailLinkInvitation({ email, projectName })
                      .then(() => {
                        console.log("Magic link has been sent!");
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  }
                })
                .catch((e) => {
                  console.error(`error message: ${e.message}`);
                });
              return false;
            } else {
              setErrors({
                ...errors,
                groupEmails: "User(s) already exists!",
              });

              return true;
            }
          }
        });
      });
  };

  const validate = (value, name) => {
    if (!value) {
      setErrors({
        ...errors,
        [name]: `Please input ${name === "groupEmails" ? "emails" : "company"}`,
      });
      return false;
    } else {
      let validationRegex;
      let error = "";
      let passingValidation = true;

      switch (name) {
        case "groupEmails":
          validationRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          error = "Please check email addresses are formatted correctly";
          const allEmails = String(value)
            .replace(/\s/g, "")
            .split(",")
            .filter((el) => el != null);
          passingValidation = allEmails.every((email) =>
            validationRegex.test(email)
          );

          break;

        case "groupCompany":
          validationRegex = /^[A-Za-z\s0-9]+$/;
          error = "Please only use alphabetic characters";
          passingValidation = validationRegex.test(value);
          break;

        default:
          break;
      }

      setErrors({
        ...errors,
        [name]: passingValidation ? "" : error,
      });

      return passingValidation;
    }
  };

  return (
    <>
      <div className="toolbar">
        <div className="toolbar-items">
          <button
            className="secondary back"
            onClick={() => {
              if (groupCompany && groupEmails) {
                setModalOpen(true);
              } else {
                history.push(`/${projectName}/users`);
              }
            }}
            disabled={loading}
          >
            <KeyboardArrowLeft />
            Back
          </button>
        </div>

        <div className="toolbar-items">
          <h1 className="users-title">
            {addGroup ? "Add group of users" : "Add user"}
          </h1>
        </div>

        <div className="toolbar-items">
          {!addGroup ? (
            <Tooltip title="Add group of users" aria-label="Add group of users">
              <button
                className="tertiary action-button"
                onClick={() => setAddGroup(true)}
                disabled={false}
              >
                <GroupAdd />
              </button>
            </Tooltip>
          ) : (
            <Tooltip title="Add user" aria-label="Add user">
              <button
                className="tertiary action-button"
                onClick={() => setAddGroup(false)}
                disabled={false}
              >
                <PersonAdd />
              </button>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="add-user-container">
        <div className="user-group-container">
          <h2>{groupCompany ? groupCompany : "Unnamed"}</h2>
          <div className="user-group-inputs">
            <div>
              <label className={`${errors.groupCompany ? "error" : ""}`}>
                Company
              </label>
              <input
                maxLength="35"
                value={groupCompany}
                label="groupCompany"
                onChange={(e) => {
                  setGroupCompany(e.target.value);
                }}
                onBlur={(e) => validate(e.target.value, "groupCompany")}
                style={{
                  border: errors.groupCompany ? "red solid 1px" : "",
                }}
              />
            </div>

            <div>
              <div className="email-container">
                <label className={`${errors.groupEmails ? "error" : ""}`}>
                  Emails
                </label>
                <Tooltip
                  style={{ fill: "#c9c9c9" }}
                  title="Add multiple email address seperated by a comma e.g. joe@example.com, jane@example.com, ..."
                  aria-label="Add multiple email address seperated by a comma e.g. joe@example.com, jane@example.com, ..."
                >
                  <Info fill="red" />
                </Tooltip>
              </div>
              <input
                value={groupEmails}
                label="Email address"
                onChange={(e) => {
                  setGroupEmails(e.target.value);
                }}
                onBlur={(e) => validate(e.target.value, "groupEmails")}
                style={{
                  border: errors.groupEmails ? "red solid 1px" : "",
                }}
              />
            </div>
          </div>

          {(errors.groupCompany || errors.groupEmails) && (
            <div className="error-container">
              <p className="error">
                {errors.groupCompany && errors.groupCompany}
              </p>
              <p className="error">
                {errors.groupEmails && errors.groupEmails}
              </p>
            </div>
          )}
        </div>

        <div className="save-container">
          <button
            className="primary"
            onClick={() => addUsers()}
            disabled={false}
          >
            {loading ? (
              <div style={{ paddingRight: "0.5rem" }}>
                <CircularProgress
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                    fill: "white",
                    color: "white",
                  }}
                />
              </div>
            ) : (
              <Done />
            )}
            Save and send invite
          </button>
        </div>

        {alerts && (
          <Snackbar
            open={true}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <div>
              {alerts.length > 0 &&
                alerts.map((alert, index) => (
                  <SnackbarContent
                    message={alert}
                    key={`snack${index}`}
                    style={{ marginBottom: "1rem" }}
                  />
                ))}
            </div>
          </Snackbar>
        )}

        <Modal
          open={modalOpen}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="modal-container">
            <div className="modal-content">
              <h2>Discard unsaved changes?</h2>
              <p></p>
              <div className="cta-container">
                <button
                  className="tertiary action-button"
                  onClick={() => {
                    setModalOpen(false);
                  }}
                  disabled={false}
                >
                  Cancel
                </button>
                <button
                  className="tertiary action-button"
                  onClick={() => {
                    setModalOpen(false);
                    history.push(`/${projectName}/users`);
                  }}
                  disabled={false}
                >
                  Discard
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default UsersGroupAdd;
