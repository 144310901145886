import React, { memo } from "react";
import PropTypes from "prop-types";

const Footer = (props) => {
  const {
    answers,
    activeQuestion,
    nextQuestion,
    previousQuestion,
    isAssessment,
    numberOfQuestions,
  } = props;

  let disable = true;

  if (isAssessment) {
    disable =
      answers &&
      answers[activeQuestion] &&
      Number.isInteger(answers[activeQuestion].score)
        ? false
        : true;
  } else {
    disable =
      answers && answers[activeQuestion] && answers[activeQuestion].value
        ? false
        : true;
  }

  return (
    <footer className="footer">
      <div className="footer-body">
        <div className="nav-item"></div>
        <div className="nav-item">
          {activeQuestion > 0 && (
            <button
              className="secondary"
              onClick={previousQuestion}
              disabled={!activeQuestion > 0}
            >
              Back
            </button>
          )}

          <button className="primary" onClick={nextQuestion} disabled={disable}>
            {activeQuestion + 1 >= numberOfQuestions ? "Complete" : "Next"}
          </button>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  activeQuestion: PropTypes.number,
  nextQuestion: PropTypes.func,
  previousQuestion: PropTypes.func,
};

Footer.defaultProps = {
  activeQuestion: 0,
  nextQuestion: () => {},
  previousQuestion: () => {},
};

export default memo(Footer);
