import React, { useState } from "react";
import handshake from "../../assets/icons/handshake.svg";
import { KeyboardArrowLeft } from "@material-ui/icons";
import { KeyboardArrowUp } from "@material-ui/icons";

import { FooterV2 } from "../../components";

const PrivacyPolicyLong = (props) => {
  const { history, match } = props;
  const [showScroll, setShowScroll] = useState(false);
  const { projectName } = match.params;

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  const hideLinks =
    match.url.includes("faq") ||
    match.url.includes("cookie-policy") ||
    match.url.includes("privacy-policy");

  return (
    <>
      <div className="page">
        <button
          className="secondary back"
          onClick={() => history.goBack()}
          disabled={false}
        >
          <KeyboardArrowLeft />
          Back
        </button>
        <div className="privacy-policy-long">
          <img src={handshake} alt="handshake icon" />
          <h1 className="title">Privacy Policy</h1>
          {/* <p className="sub-title subtitle ">
            <strong>
              Your privacy is important to us. As it is your first time here,
              please read and accept our privacy policy. It explains what we do
              and how your data will be protected.
            </strong>
          </p> */}
          <div className="body">
            <h2>EQUALITY FORWARD CONSULTING’S PRIVACY NOTICE</h2>
            <p>
              This online assessment is provided by Equality Forward Consulting
              Ltd.
              <br />
              <br />
              Equality Forward Consulting Ltd is committed to the protection of
              any personal data we obtain from you through our website or
              through any other means. This Privacy Notice describes the types
              of information we collect from you, how we use it, how you can
              manage the information we have about you, and how you can contact
              us. The information to which our Privacy Notice applies is
              “personal data” (which is defined in current data privacy law as
              information which can identify living people). Where we refer to
              personal data below, we use the term “personal information”.
              <br />
              <br />
              The contents of our Privacy Notice may change from time to time.
              This Privacy Notice was last updated on 7 December 2020.
            </p>
            <p className="subtitle">
              <strong> Who we are</strong>
            </p>
            <p>
              We are Equality Forward Consulting Ltd. We are a company
              registered in England and Wales. Our registered office address is
              at 3 Lyttleton Court, Birmingham Street, Halesowen, West Midlands,
              England, B63 3HN. Our registered number at Companies House is
              12870068. We are registered with the UK Information Commissioner’s
              Office, with number <strong>ZA827972.</strong>
            </p>
            <p className="subtitle">
              <strong>How do we collect your personal information?</strong>
            </p>
            <h2>
              WE DO NOT COLLECT PERSONAL INFORMATION DIRECTLY FROM CHILDREN.
            </h2>

            <p>
              We collect your personal information in the following ways:
              <ul>
                <li>if you take party in our online assessment tool.</li>
                <li>
                  if you are seeking general information about Equality Forward
                  Consulting and what we do, when you contact us through our
                  website or via email or otherwise.
                </li>
              </ul>
              We are required to have a lawful justification for collecting and
              processing your personal data. The lawful justification on which
              we rely is that our limited collecting and processing of personal
              data is in our “legitimate business interests”. In identifying
              this justification, we have taken into account the likely privacy
              impact on you. We consider that “legitimate interests” applies in
              particular because we only collect the minimum of data that we
              need and because we only process your personal data in ways that
              you might expect in the context of an online assessment tool
              provided by your employer via our platform.
            </p>
            <p className="subtitle">
              <strong>What personal information do we collect?</strong>
            </p>
            <p>
              Depending on the circumstances, the personal information we
              collect directly from you might be all or some of the following:
              <ul>
                <li>your name;</li>
                <li>your email address;</li>
                <li>your company role/position;</li>
                <li>username and password;</li>
                <li>
                  your gender and age (but we do not collect this to identify
                  you as an individual, we only collect this to understand the
                  demography of those taking the assessment);
                </li>
                <li>assessment submissions;</li>
              </ul>
              In addition, we will collect via the Google analytics cookie if
              you consent to it:
              <ul>
                <li>the IP address of the computer you use; </li>
                <li>
                  the internet browser from which you visited our website;
                </li>
                <li>
                  the pages you visit on our website and how long you viewed
                  them for.
                </li>
              </ul>
              Our service then involves the production of an automated report
              based on your assessment submissions. We send this to you by email
              and we also share this report with your employer.
            </p>
            <p className="subtitle">
              <strong>How we use personal information we collect</strong>
            </p>
            <p>
              We use all information we collect so that we can provide our
              online assessment tool, provide you and our employer clients with
              automated reports, provide information about our service and
              answer enquiries about our business.
            </p>
            <p className="subtitle">
              <strong>Use of aggregated data</strong>
            </p>
            <p>
              Aggregated data is completely anonymous and is data from which an
              individual can no longer be identified. We turn the submissions we
              collect into aggregated data so that we can use this aggregated
              data to conduct further research and better inform our clients of
              data trends and solutions. We do not use any personal information
              in this way.
            </p>

            <p className="subtitle">
              <strong>How long do we keep your personal information?</strong>
            </p>
            <p>
              We will only retain your personal information for as long as
              reasonably necessary to fulfil the purposes we collect it for,
              including for the purposes of satisfying any legal, regulatory,
              tax, accounting or reporting requirements. We may retain your
              personal information for a longer period in the event of a
              complaint or if we reasonably believe there is a prospect of
              litigation.
              <br />
              <br />
              We typically delete all personal information after one year.
            </p>

            <p className="subtitle">
              <strong>Security</strong>
            </p>
            <p>
              We take all appropriate technical and organisational measure to
              ensure the integrity and security of the personal information held
              by Equality Forward Consulting.
            </p>

            <p className="subtitle">
              <strong>Sharing your personal information</strong>
            </p>
            <p>
              We require all third parties who have access to the personal data
              we hold, to respect the security of your personal data and to
              treat it in accordance with the law.
            </p>

            <p className="subtitle">
              <strong>Sharing your personal information</strong>
            </p>
            <p>
              Because our contract with employer clients provides for this and
              we consider this sharing to be in our “legitimate interests” as a
              business and those of our employer clients, we share with our
              employer clients, certain identifying information together with
              the automated reports that are produced once an individual has
              provided their submissions through the online assessment.
              <br />
              <br />
              We also share personal information with any IT service provider
              that works for us (e.g. to store our data) as a data processor.
              Any such service provider may have access to your limited personal
              data where necessary for the provision of their service. We do not
              allow our service providers to use your personal data for their
              own purposes and only permit them to process your personal data
              for specified purposes and in accordance with our instructions.
              <br />
              <br />
              We may also share your personal data with third parties to whom we
              may choose to sell, transfer or merge parts of our business or our
              assets. Alternatively, we may seek to acquire other businesses or
              merge with them. If a change happens to our business, then the new
              owners may use your personal data in the same way as set out in
              this privacy policy.
              <br />
              <br />
              We may also share or reveal personal information if we are obliged
              or permitted to do so by law, or if it is required by law
              enforcement, fraud prevention, credit reference agencies, tax
              authorities or regulatory bodies.
            </p>

            <p className="subtitle">
              <strong>Exporting your personal information out of the UK</strong>
            </p>
            <p>
              We store data on the cloud through Google’s UK service. We don’t
              typically transfer data outside the UK - unless your employer is
              based outside the UK. If we need to transfer data outside of the
              UK, we will only do so where we have a lawful reason and we will
              put in place appropriate safeguards to ensure that all personal
              data under our control is protected outside the UK to the same
              standard as if it were kept within the UK.
            </p>

            <p className="subtitle">
              <strong>Managing personal information</strong>
            </p>
            <p>
              You have the right as an individual to manage the personal
              information we hold about you and make amendments if this is
              necessary. You are also able to withdraw any consent you might
              have given us – for example in respect of the cookies on our
              website.  You may also object to Equality Forward Consulting using
              your personal information even if we rely on our legitimate
              interests to make use of it.
              <br />
              <br />
              You have several rights in relation to your personal information
              under data protection law.  In relation to most of these, if you
              exercise them we may ask for information from you to confirm your
              identity and, where applicable, to help us find your personal
              information.  We will respond to you within thirty days after we
              have received a valid request, by which we mean a request that is
              backed up with any identification documents that we have
              requested.
              <br />
              <br />
              You have the following rights:
              <ul>
                <li>
                  to request a copy or summary of the personal information that
                  we hold about you and to receive it from us within 30 days
                </li>
                <li>to correct and update your personal information</li>
                <li>
                  to withdraw your consent (where we rely on it) in respect of
                  matters for which your consent has already been given
                </li>
                <li>to erase your personal information or restrict its use</li>
                <li>
                  to complain to the Information Commissioner’s Office (ICO) as
                  our relevant United Kingdom supervisory authority regulating
                  data privacy matters.
                </li>
              </ul>
            </p>

            <p className="subtitle">
              <strong>Cookies</strong>
            </p>
            <p>
              Our website uses cookies to distinguish you from other users of
              our website. This helps us to provide you with a good experience
              when you browse our website, and also allows us to improve our
              site.
              <br />
              <br />
              A cookie is a small file of letters and numbers that we store on
              your browser or the hard drive of your computer if you agree.
              Cookies contain information that is transferred to your computer's
              hard drive.
              <br />
              <br />
              We use different types of cookies for different reasons. You don’t
              have to accept our use of NON-ESSENTIAL COOKIES described below
              and you can choose to disable these if you wish. The following
              list sets out the types of cookies we use:
              <ul>
                <li>
                  <strong>Strictly necessary cookies. </strong>These are cookies
                  that are required for the operation of our website. They
                  include, for example, cookies that enable you to securely log
                  in to our platform.
                </li>
                <li>
                  <strong>Functionality cookies.</strong> These are used to
                  recognise you when you return to our platform.
                </li>
                <li>
                  <strong>
                    Analytical/performance cookies (NON-ESSENTIAL).
                  </strong>
                  These allow us to recognise and count the number of website
                  visitors and to see how visitors move around our website when
                  they are using it. This helps us to improve the way our
                  website works, for example, by ensuring that users are finding
                  what they are looking for easily.
                </li>
              </ul>
              Here are more details about the NON-ESSENTIAL analytical cookies
              we use, the purposes for which we use them and how you can disable
              them if you wish to do so:
              <table
                style={{
                  width: "100%",
                  borderSpacing: 0,
                  overflow: "auto",
                  display: "block",
                }}
              >
                <tr>
                  <th style={{ border: "solid 1px gray", padding: "0.5rem" }}>
                    Name
                  </th>
                  <th style={{ border: "solid 1px gray", padding: "0.5rem" }}>
                    Purpose
                  </th>
                  <th style={{ border: "solid 1px gray", padding: "0.5rem" }}>
                    To Disable
                  </th>
                </tr>
                <tr>
                  <td style={{ border: "solid 1px gray", padding: "0.5rem" }}>
                    Google Analytics
                  </td>
                  <td style={{ border: "solid 1px gray", padding: "0.5rem" }}>
                    Analytical/performance - to understand how the website is
                    used.
                  </td>
                  <td style={{ border: "solid 1px gray", padding: "0.5rem" }}>
                    You can block cookies by activating the setting on your
                    browser that allows you to refuse the setting of all or some
                    cookies. However, if you use your browser settings to block
                    all cookies (including essential cookies) you may not be
                    able to access all or parts of our website.
                    <br />
                    <br />
                    <a href="https://tools.google.com/dlpage/gaoptout">
                      https://tools.google.com/dlpage/gaoptout
                    </a>
                  </td>
                </tr>
              </table>
            </p>

            <p className="subtitle">
              <strong>Contacting us</strong>
            </p>

            <p>
              You can exercise your rights and manage your information by
              contacting us at:  info@leadershipequalityindex.
            </p>

            <p className="subtitle">
              <strong>Issues and complaints</strong>
            </p>

            <p>
              If you have an issue regarding how we handle personal information,
              please do not hesitate to contact us. We will do everything we can
              to try to resolve your issue. If however you need to make a
              complaint, the contact details of the ICO are as follows:
              <br />
              <br />
              Information Commissioner’s Office, Wycliffe House, Water Lane,
              Wilmslow, Cheshire SK9 5AF
              <br />
              Telephone number: 0303 123 1113
              <br />
              Website: https://ico.org.uk/global/contact-us/
            </p>
          </div>
          <button
            className="secondary see-more"
            onClick={scrollTop}
            disabled={false}
          >
            <KeyboardArrowUp />
            Go to the top
          </button>
        </div>
      </div>

      <FooterV2 projectName={projectName} hideLinks={hideLinks} />
    </>
  );
};

export default PrivacyPolicyLong;
