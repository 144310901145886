import React, { memo, useState, useEffect } from "react";

import { UsersAdd, UsersGroupAdd, FooterV2 } from "../../components";

import firebase from "../../firebase";

const AddUser = ({ history, currentUserEmail, match }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [addGroup, setAddGroup] = useState(false);
  const [loading, setLoading] = useState(false);

  const { projectName } = match.params;

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            /**
             * CHECK CURRENT USER IS ADMIN
             */
            const { claims } = idTokenResult;

            if (!claims.isAdmin) {
              history.goBack();
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });
  }, [history]);

  return (
    <>
      <div className="page">
        {!addGroup ? (
          <UsersAdd
            currentUserEmail={currentUserEmail}
            history={history}
            projectName={projectName}
            match={match}
            loading={loading}
            setLoading={setLoading}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            addGroup={addGroup}
            setAddGroup={setAddGroup}
          />
        ) : (
          <UsersGroupAdd
            currentUserEmail={currentUserEmail}
            history={history}
            projectName={projectName}
            match={match}
            loading={loading}
            setLoading={setLoading}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            addGroup={addGroup}
            setAddGroup={setAddGroup}
          />
        )}
      </div>

      <FooterV2 projectName={projectName} />
    </>
  );
};

export default memo(AddUser);
