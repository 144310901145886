import React, { memo } from "react";
import {
  ThumbUpOutlined,
  ErrorOutlineOutlined,
  PanToolOutlined,
} from "@material-ui/icons";

import {
  feedbackDetails,
  sectionCalculations,
  userSubSectionCalculations,
  subSectionCalculations,
} from "../../utils";

const Summary = (props) => {
  const {
    isCompany,
    sectionsNormGroup,
    selectedUsers,
    userAssessmentData,
    isUserReport,
  } = props;

  return (
    <>
      {sectionsNormGroup &&
        Object.keys(sectionsNormGroup).length > 0 &&
        Object.keys(sectionsNormGroup).map((section) => {
          const sectionPercentileRank = sectionCalculations(
            sectionsNormGroup,
            section,
            selectedUsers,
            isUserReport,
            userAssessmentData
          );

          const { feedbackColor, feedbackScore } = feedbackDetails(
            sectionPercentileRank
          );

          return (
            <div
              className="section-summary"
              style={{ background: feedbackColor }}
              key={section}
            >
              <div className="section-summary-header">
                <div className="section-summary-title">
                  <h2>{section}</h2>
                  {/* {feedbackScore === "above average" && <ThumbUpOutlined />}
                  {feedbackScore === "below average" && <PanToolOutlined />}
                  {feedbackScore === "average" && <ErrorOutlineOutlined />} */}
                </div>

                <p>
                  {isCompany
                    ? `Company results were ${feedbackScore} for industry comparison. Speak to your consultant to find out more.`
                    : `Your results were ${feedbackScore} for your peer group. Speak to your consultant to find out more.`}
                </p>
              </div>
              <div className="section-summary-body">
                <p className="subtitle">
                  <strong>Your results</strong>
                </p>

                {Object.keys(sectionsNormGroup[section])
                  .sort()
                  .map((subSection) => {
                    let subsectionPercentileRank = 0;
                    if (isUserReport) {
                      subsectionPercentileRank = userSubSectionCalculations(
                        sectionsNormGroup,
                        section,
                        subSection,
                        userAssessmentData &&
                          userAssessmentData[section] &&
                          userAssessmentData[section][subSection]
                      );
                    } else {
                      subsectionPercentileRank = subSectionCalculations(
                        sectionsNormGroup,
                        section,
                        subSection,
                        selectedUsers
                      );
                    }

                    const { feedbackColor } = feedbackDetails(
                      subsectionPercentileRank
                    );

                    return (
                      <div className="feedback-container" key={subSection}>
                        <div className="feedback-title">
                          <div
                            className="feedback-color"
                            style={{ background: feedbackColor }}
                          />
                          <p>{subSection}</p>
                        </div>
                        <p className="">
                          <strong>{subsectionPercentileRank}</strong>%
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default memo(Summary);
